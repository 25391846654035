import { Button } from 'bootstrap';
import React, { Component,useState } from 'react';
import SpaceModal from '@gsk-space-components/space-modal';
import { ThemeProvider, OutlinedInput, TextField } from '@material-ui/core';
import { parseJSON } from 'jquery';
import { SpaceAutocomplete } from '@gsk-space-components/autocomplete';
import { msalAuth, getAccessToken, getUserRoles, getUserName } from '../msal/MsalAuthProvider';
import Select from 'react-select';

import "react-datepicker/dist/react-datepicker.css";
import './../custom.css';

let accessToken = undefined;
let storedProcess=[];
let storedSupply=[];
let action = "";
let routesToRetrieve = "";
let decisionToRetrieve = "";
  
export class ProcessBooking extends Component {
    static displayName = ProcessBooking.name;

    //Checks before submitting the Process Data
    onSubmit = () => {
        storedSupply=localStorage.getItem('SupplyBooking')
        if(storedSupply===""||storedSupply===null){
         this.setState({showAlert:true});
        }else if(this.state.process!==""&&this.state.process!==null){
            this.setState({  showAllProcess: false, showAddEditProcess:true,showAlert: false });
        }
    }

    //Default Constructor
    constructor(props) {
        super(props);  
        this.state = {
            selectedOption:[],
            changePartDetails:[],
            isAdmin: true,
            batchScale: "",
            zone: "",
            process:[],
            supply: [],
            processMaster: [],
            showAllProcess: true,
            showAddEditProcess: false,
            selectedProcessID: 0,
            processUID: "",
            requestorName: 0,
            stepIndex: 0,
            durationPerBatch: 0,
            equipmentUID: "",
            equipmentName: "",
            reqFlexibleRoom: "",
            roomUID: "",
            roomLocation: "",
            roomNumber: "",
            equipmentChangePart:"",
            updateProcess: false,
            updatedProcessID: "",
            deletedProcessID: "",
            loading: true,
            showConfirmationDialog: false,
            showDeleteConfirmationDialog: false,
            showSearchLoading: false,
            showProcessUIDError: false,
            showRequestorNameError: false,
            showStepIndexError: false,
            showDurationError: false,
            showEquipUIDError: false,
            showEquipNameError: false,
            showFlexibleRoomError: false,
            showRoomUidError: false,
            showLocationError: false,
            showRoomNumberError: false,
            showAlert:false,
            deletionComment: "",
            showCommentError: false,
            allEquipmentChangeParts: [],
            filteredRequestors: [],
            allEquipments: [],
            allRooms: [],
            roomLocationKeywords: []
        };

        this._submitProcess = this._submitProcess.bind(this);
        this._deleteProcess = this._deleteProcess.bind(this);
        this._validateProcessInput = this._validateProcessInput.bind(this);
    }

    //Component Did Mount Logic
    async componentDidMount() {
        const accessTokenRequest = {
            scopes: ["api://beefd1ec-7166-4ef5-a037-80d953594bbf/access_as_user"]
        }
        var authRes;
        try {
            authRes = await msalAuth.acquireTokenSilent(accessTokenRequest);
            accessToken = authRes.accessToken
        }
        catch (error) {
            console.log("AquireTokenSilent failure");
            authRes = await msalAuth.acquireTokenPopup(accessTokenRequest);
            accessToken = authRes.accessToken
        }
        this.setVal();
        this.populateProcessData();
        this.populateEquipmentChangePartData();
        this.getAllEquipmentData();
        this._getEquipmentType();
        this.getAllRoomsData();
        
    }

    //Set Equipment Change Part
    handleChange = selectedOption => {
        this.setState({ selectedOption });

        var values = selectedOption
        .map(function (item, i) {
          return item["label"];
        })
        .join(",");
          this.setState({equipmentChangePart:values});
    };

    //Renders the page based on New/Edit Request
    render() {
        
        return (
            <div>
               
                {this.state.loading && <p><gsk-circular-progress> </gsk-circular-progress></p>}
                {!this.state.loading && this.state.showAllProcess &&
                    <div>
                        <div style={{ textAlign: "right" }}>
                            {<button title="Create New Process" className={"btn-add"} onClick={() => {
                                this.onSubmit();
                            }}>Add Process</button>}
                        </div>
                        {this.state.showAlert && <SpaceModal
                            open={this.state.showAlert}
                            titleText="Add Supply"
                            body="Please add supply requirement to add process."
                            mainButtonLabel="Close"
                            mainButtonFunction={() => { this.setState({ showAllSupply: true, showAlert: false }) }}
                            id="example-3"
                        />}
                        
                        <div className={"rootContent"}>{this.renderprocessTable(this.state.process)}</div>
                    </div>}
                {!this.state.loading && this.state.showAddEditProcess && <div>{this.renderNewProcessScreen()}</div>}
                {this.state.showConfirmationDialog && <SpaceModal
                    open={this.state.showConfirmationDialog}
                    titleText="Save Process"
                    body="Would you like to save this Process?"
                    mainButtonLabel="Yes"
                    secondaryButtonLabel="No"
                    mainButtonFunction={this._submitProcess}
                    secondaryButtonFunction={() => { this.setState({ showAddEditProcess: false, showAllProcess: true, showConfirmationDialog: false }) }}
                    id="confirm-submit-Process"
                />}
                {this.state.showDeleteConfirmationDialog && <SpaceModal
                    open={this.state.showDeleteConfirmationDialog}
                    titleText="Delete Process"
                    body={<div>Would you like to delete this Process?</div>}
                    mainButtonLabel="Yes"
                    secondaryButtonLabel="No"
                    mainButtonFunction={this._deleteProcess}
                    secondaryButtonFunction={() => { this.setState({ showAddEditProcess: false, showAllProcess: true, showConfirmationDialog: false, showDeleteConfirmationDialog: false }) }}
                    id="confirm-delete-Process"
                />}
            </div>
        );
    }

    //Renders the Existing Process Table
    renderprocessTable(process) {
        return (
            <table className='table table-striped' aria-labelledby="tabelLabel">
                <thead>
                    <tr>
                        <th>Process UID</th>
                        <th>No of Operator Assigned</th>
                        <th>Step Index</th>
                        <th>Duration Per Batch</th>
                        <th>Equipment UID</th>
                        <th>Equipment Name</th>
                        <th>Equipment Change Part</th>
                        <th>Requires Flexible Room</th>
                        <th>Room UID</th>
                        <th>Room Location</th>
                        <th>Room Number</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {(process === undefined || process === null || process.length === 0) && <tr><td colSpan={16}><div className={"no-record-error"}>No records found</div></td></tr>}
                    {process.map(process =>
                        <tr>
                            <td>{process.ProcessUID}</td>
                            <td>{process.OperatorAssigned}</td>
                            <td>{process.StepIndex}</td>
                            <td>{process.DurationPerBatch}</td>
                            <td>{process.EquipmentUID}</td>
                            <td>{process.EquipmentName}</td>
                            <td>{process.EquipmentChangePart}</td>
                            <td>{process.RequiresFlexibleRoom}</td>
                            <td>{process.RoomUID}</td>
                            <td>{process.RoomLocation}</td>
                            <td>{process.RoomNumber}</td>
                            {<td><div>
                                <button title="Edit Equipment" className={"btn-edit"} onClick={() => {
                                    let allChangeParts = [];
                                    if(process.EquipmentChangePart!== undefined && process.EquipmentChangePart!== null && process.EquipmentChangePart.length > 0){
                                        let listOfChangePart = process.EquipmentChangePart.split(",");
                                        for (let i = 0; i < listOfChangePart.length; i++) {
                                            allChangeParts.push({
                                                "value": listOfChangePart[i],
                                                "label": listOfChangePart[i]
                                            });
                                        }
                                    }
                                    this.setState({
                                        updateProcess: true,
                                        updatedProcessID: process.ID,
                                        requestorName: process.OperatorAssigned,
                                        showAllProcess: false,
                                        showAddEditProcess: true,
                                        selectedProcessID: process.Id,
                                        processUID: process.ProcessUID,
                                        stepIndex: process.StepIndex,
                                        durationPerBatch: process.DurationPerBatch,
                                        equipmentUID: process.EquipmentUID,
                                        equipmentName: process.EquipmentName,
                                        equipmentChangePart:process.EquipmentChangePart,
                                        selectedOption:allChangeParts,
                                        reqFlexibleRoom:process.RequiresFlexibleRoom,
                                        roomUID: process.RoomUID,
                                        roomLocation: process.RoomLocation,
                                        roomNumber: process.RoomNumber
                                    });
                                }}>Edit</button>
                                <button title="Delete Equipment" className={"btn-delete"} onClick={() => {
                                    this.setState({
                                        showDeleteConfirmationDialog: true,
                                        selectedProcessID: process.Id,
                                        deletedProcessID: process.Id
                                    });
                                }}>Delete</button>
                            </div></td>}
                        </tr>
                    )}
                </tbody>
            </table>
        );
    }

    //Renders the New Process Screen based on Cascading Selection
    renderNewProcessScreen() {
        let listOfEquipmentNames = [];
        let listOfEquipmentChangePartNames = [];
        let listOfEquipmentIds = [];
        let listOfRoomIds = [];
        let listofEquipmentType = [];

        let storedSupplyArray = JSON.parse(localStorage.getItem("SupplyBooking") || '{}');
        let storedSupply = storedSupplyArray[0];

        let storedInfoArray = JSON.parse(localStorage.getItem("InformationBooking") || '{}');
        let storedInfo = storedInfoArray[0];

        listofEquipmentType = this.state.equipmentTypeKeywords.map(itm => itm.Keyword);
        listOfRoomIds = this.state.allRooms.filter(itm => itm.ZoneUID.trim() === storedSupply.Compound_Type).map(itm => itm.RoomUID);

        if (this.state.equipmentUID !== undefined && this.state.equipmentUID !== null && this.state.equipmentUID.length !== 0) {
            listOfEquipmentChangePartNames = this.state.allEquipmentChangeParts.filter(itm => itm.EquipmentUid === this.state.equipmentUID).map(itm => itm.ChangePartName);
        }

        if (this.state.processUID === undefined || this.state.processUID === null || this.state.processUID.length === 0) {
            listOfEquipmentNames = [];
            listOfEquipmentIds = [];
        } else if (this.state.equipmentName === undefined || this.state.equipmentName === null || this.state.equipmentName.length === 0) {
            listOfEquipmentNames = this.state.allEquipments.filter(itm => itm.ProcessUid === this.state.processUID);
            listOfEquipmentNames = listOfEquipmentNames.filter(itm => itm.ZoneUid === storedSupply.Compound_Type);
            listOfEquipmentNames = listOfEquipmentNames.filter(itm => itm.BatchScale.includes(storedInfo.BatchScale)).map(itm => itm.EquipmentName);
            listOfEquipmentNames = [...new Set(listOfEquipmentNames)];
            listOfEquipmentIds = this.state.allEquipments.filter(itm => itm.ProcessUid === this.state.processUID);
            listOfEquipmentIds = listOfEquipmentIds.filter(itm => itm.ZoneUid === storedSupply.Compound_Type);
            listOfEquipmentIds = listOfEquipmentIds.filter(itm => itm.BatchScale.includes(storedInfo.BatchScale)).map(itm => itm.EquipmentUid);
        } else {
            listOfEquipmentIds = this.state.allEquipments.filter(itm => itm.EquipmentName === this.state.equipmentName && itm.ProcessUid === this.state.processUID);
            listOfEquipmentIds = listOfEquipmentIds.filter(itm => itm.ZoneUid === storedSupply.Compound_Type);
            listOfEquipmentIds = listOfEquipmentIds.filter(itm => itm.BatchScale.includes(storedInfo.BatchScale)).map(itm => itm.EquipmentUid);
        }
        const listofEquipmentChangePart=[];
        for (let item of listOfEquipmentChangePartNames){
            listofEquipmentChangePart.push({ value: item, label: item});
           console.log(listofEquipmentChangePart);
        }

        action = localStorage.getItem('Action');
        if (action === "Edit") {
            listOfEquipmentNames = this.state.allEquipments.filter(itm => itm.ProcessUid === this.state.processUID);
            listOfEquipmentNames = listOfEquipmentNames.filter(itm => itm.ZoneUid === storedSupply.Compound_Type);
            listOfEquipmentNames = listOfEquipmentNames.filter(itm => itm.BatchScale.includes(storedInfo.BatchScale)).map(itm => itm.EquipmentName);
            listOfEquipmentNames = [...new Set(listOfEquipmentNames)];
        }

        return (<div>
            <gsk-card image="/imagess/documentation.png" elevation="6">
                <div slot="content" className="gsk-card__content">
                    <div className="formcontainer">
                        <div className="formrow">
                            <div className="col-25">
                                <label >Process UID</label>
                            </div>
                            <div className="col-75">
                                <SpaceAutocomplete
                                    id="basic-test"
                                
                                    options={listofEquipmentType}
                                    getOptionLabel={(option) => option}
                                    fullWidth={true}
                                    placeholder="Select Process UID.."
                                    value={this.state.processUID}
                                    onChange={(_event, newValue) => {
                                        this.setState({
                                            processUID: newValue,
                                            showProcessUIDError: (newValue === undefined || newValue === ""),
                                            equipmentUID: "",
                                            equipmentName: ""
                                        })
                                    }}
                                    error={this.state.showProcessUIDError}
                                />
                            </div>
                        </div>
                        <div className="formrow">
                            <div className="col-25">
                                <label >No of Operator Assigned</label>
                            </div>
                            <div className="col-75">
                                <OutlinedInput fullWidth={true} type="number" required placeholder={"Select No of Operator Assigned.."} value={this.state.requestorName} error={this.state.showRequestorError}
                                    onChange={(e) => { this.setState({ requestorName: (e.target.value > -1) ? e.target.value : this.state.requestorName, showRequestorError: (!(Number(e.target.value) > -1) && !this.state.showRequestorError) }); }}
                                ></OutlinedInput>
                            </div>
                        </div>
                        <div className="formrow">
                            <div className="col-25">
                              <label >Step Index</label>
                             </div>
                                    <div className="col-75">
                                        <OutlinedInput fullWidth={true} type="number" required placeholder={"Enter Step Index.."} value={this.state.stepIndex} error={this.state.showStepIndexError}
                                    onChange={(e) => { this.setState({ stepIndex : (Number(e.target.value) && e.target.value > 0) ? e.target.value : this.state.stepIndex, showStepIndexError: (e.target.value === undefined || e.target.value === "") }); }}
                                ></OutlinedInput>
                            </div>
                        </div>

                        <div className="formrow">
                            <div className="col-25">
                                <label >Duration Per Batch(Days)</label>
                            </div>
                            <div className="col-75">
                                <OutlinedInput fullWidth={true} type="number" decimalPlaces="2" required placeholder={"Enter Duration Per Batch.."} value={this.state.durationPerBatch} error={this.state.showDurationError}
                                    onChange={(e) => { this.setState({ durationPerBatch: (Number(e.target.value) && (e.target.value > 1 || e.target.value == 1)) ? e.target.value : this.state.durationPerBatch, showDurationError: (e.target.value === undefined || e.target.value === "") }); }}
                                ></OutlinedInput>
                            </div>
                        </div>
                        <div className="formrow">
                            <div className="col-25">
                                <label >Equipment Name</label>
                            </div>
                            <div className="col-75">
                                <SpaceAutocomplete
                                    id="basic-test"
                                    options={listOfEquipmentNames}
                                    getOptionLabel={(option) => option}
                                    fullWidth={true}
                                    placeholder="Select Equipment Name.."
                                    value={this.state.equipmentName}
                                    onChange={(_event, newValue) => {
                                        this.setState({
                                            equipmentName: newValue,
                                            showEquipNameError: (newValue === undefined || newValue === "")                                      })
                                    }}
                                    error={this.state.showEquipNameError}
                                />
                            </div>
                        </div>
                        <div className="formrow">
                            <div className="col-25">
                                <label >Equipment UID</label>
                            </div>
                            <div className="col-75">
                                <SpaceAutocomplete
                                    id="basic-test"
                                    options={listOfEquipmentIds}
                                    getOptionLabel={(option) => option}
                                    fullWidth={true}
                                    placeholder="Select Equipment UID.."
                                    value={this.state.equipmentUID}
                                    onChange={(_event, newValue) => {
                                        this.setState({
                                            equipmentUID: newValue,
                                            showEquipUIDError: (newValue === undefined || newValue === ""),
                                            equipmentName: this.state.allEquipments.filter(itm => itm.EquipmentUid === newValue).map(itm => itm.EquipmentName).join(',')
                                        })
                                    }}
                                    error={this.state.showEquipUIDError}
                                />
                            </div>
                        </div>
                        <div className="formrow">
                            <div className="col-25">
                                <label >Equipment Change Part</label>
                            </div>
                            <div className="col-75">
                                <Select
                            isMulti={true}
                            placeholder="Select Equipment Change Part"
                            value={this.state.selectedOption}
                           options={listofEquipmentChangePart}
                            onChange={this.handleChange}
                         />                              
                            </div>
                        </div>
                        <div className="formrow">
                            <div className="col-25">
                                <label >Requires Flexible Room?</label>
                            </div>
                            <div className="col-75">
                                <SpaceAutocomplete
                                    id="basic-test"
                                    options={["Y", "N"]}
                                    getOptionLabel={(option) => option}
                                    fullWidth={true}
                                    placeholder="Please select Requires Flexible Room.."
                                    value={this.state.reqFlexibleRoom}
                                    error={this.state.showFlexibleRoomError}
                                    onChange={(_event, newValue) => { this.setState({ reqFlexibleRoom: newValue, showFlexibleRoomError: (newValue === undefined || newValue === "") }) }}
                                />
                            </div>
                        </div>

                        <div className="formrow">
                            <div className="col-25">
                                <label >Room ID</label>
                            </div>
                            <div className="col-75">
                                <SpaceAutocomplete
                                    id="roomLocation"
                                    options={listOfRoomIds}
                                    getOptionLabel={(option) => option}
                                    fullWidth={true}
                                    placeholder="Select Room ID.."
                                    value={this.state.roomUID}
                                    onChange={(_event, newValue) => {
                                        this.setState({
                                            roomUID: newValue,
                                            showRoomUidError: (newValue === undefined || newValue === ""),
                                            roomNumber: this.state.allRooms.filter(itm => itm.RoomUID === newValue).map(itm => itm.RoomNumber).join(','),
                                            roomLocation: this.state.allRooms.filter(itm => itm.RoomUID === newValue).map(itm => itm.Location).join(',')
                                        })
                                    }}
                                    error={this.state.showRoomUidError}
                                />
                            </div>
                        </div>
                        <div className="formrow">
                            <div className="col-25">
                                <label >Room Number</label>
                            </div>
                            <div className="col-75">
                                <OutlinedInput fullWidth={true} required disabled placeholder={"Enter Room Number.."} value={this.state.roomNumber} error={this.state.showRoomNumberError}
                                    onChange={(e) => { this.setState({ roomNumber: e.target.value, showRoomNumberError: (e.target.value === undefined || e.target.value === "") }); }}
                                ></OutlinedInput>
                            </div>
                        </div>
                        <div className="formrow">
                            <div className="col-25">
                                <label >Room Location</label>
                            </div>
                            <div className="col-75">
                                <SpaceAutocomplete
                                    id="roomLocation"
                                    options={this.state.roomLocationKeywords.map(itm => itm.Keyword)}
                                    getOptionLabel={(option) => option}
                                    fullWidth={true}
                                    disabled
                                    placeholder="Select Room Location.."
                                    value={this.state.roomLocation}
                                    onChange={(_event, newValue) => { this.setState({ roomLocation: newValue, showLocationError: (newValue === undefined || newValue === "") }) }}
                                    error={this.state.showLocationError}
                                />
                            </div>
                        </div>
                        <div className="formrow">
                        <button className={"submitBtn"} onClick={this._validateProcessInput}>Add</button>
                            <button className={"cancelBtn"} onClick={() => {
                                this.setState({
                                    showAddEditProcess: false,
                                    showAllProcess: true,
                                    showProcessUIDError: false,
                                    showRequestorNameError: false,
                                    showStepIndexError: false,
                                    showDurationError: false,
                                    showEquipUIDError: false,
                                    showEquipNameError: false,
                                    showFlexibleRoomError: false,
                                    showRoomUidError: false,
                                    showLocationError: false,
                                    showRoomNumberError: false,
                                });
                            }}>Cancel</button>
                        </div>
                    </div>
                </div>
            </gsk-card>
        </div >);
    }

    //Gets all the process details
    async populateProcessData() {
        const response = await fetch('Process', {
            method: 'get',
            headers: new Headers({
                'Authorization': 'Bearer ' + accessToken
            })
        });
        if (response.ok) {
            const data = await response.json();
            let userRoles = getUserRoles();
            let hasAdminRole = userRoles.indexOf("Admin") > -1;
            this.setState({ isAdmin: hasAdminRole, process: data, loading: false, showAllProcess: true, showAddEditProcess: false, showConfirmationDialog: false, showDeleteConfirmationDialog: false, selectedProcessID: 0 });
        }
        else {
            this.setState({ isAdmin: false, process: [], loading: false, showAllProcess: true, showAddEditProcess: false, showConfirmationDialog: false, showDeleteConfirmationDialog: false, selectedProcessID: 0 });
        }
    }

    //Populate Equipment Change Part Details
    async populateEquipmentChangePartData() {
        const response = await fetch('EquipmentsChangePart', {
            method: 'get',
            headers: new Headers({
                'Authorization': 'Bearer ' + accessToken
            })
        });
        if (response.ok) {
            const data = await response.json();
            this.setState({ allEquipmentChangeParts: data  });
        }
        else {
            this.setState({ allEquipmentChangeParts: [] });
        }
    }

    //Validates the Input provided by User
    _validateProcessInput() {
        let canSave = true;
        if (!this.state.processUID) {
            this.setState({ showProcessUIDError: true });
            canSave = false;
        }
        if (Number(this.state.requestorName) < 0) {
            this.setState({ showRequestorNameError: true });
            canSave = false;
        }
        if (Number(this.state.stepIndex) < 1) {
            this.setState({ showStepIndexError: true });
            canSave = false;
        }
        if (Number(this.state.durationPerBatch) < 1) {
            this.setState({ showDurationError: true });
            canSave = false;
        }
        if (!this.state.equipmentName) {
            this.setState({ showEquipNameError: true });
            canSave = false;
        }
        if (!this.state.equipmentUID) {
            this.setState({ showEquipUIDError: true });
            canSave = false;
        }
        if (!this.state.reqFlexibleRoom) {
            this.setState({ showFlexibleRoomError: true });
            canSave = false;
        }
        if (!this.state.roomUID) {
            this.setState({ showRoomUidError: true });
            canSave = false;
        }
        if (!this.state.roomLocation) {
            this.setState({ showLocationError: true });
            canSave = false;
        }
        if (!this.state.roomNumber) {
            this.setState({ showRoomNumberError: true });
            canSave = false;
        }

        if (canSave) {
            this.setState({
                showProcessUIDError: false,
                showRequestorNameError: false,
                showStepIndexError: false,
                showDurationError: false,
                showEquipUIDError: false,
                showEquipNameError: false,
                showFlexibleRoomError: false,
                showRoomUidError: false,
                showLocationError: false,
                showRoomNumberError: false,
                showConfirmationDialog: true
            });
        }
    }

    //Submits the Process Detail
    _submitProcess() {
        if (this.state.updateProcess) {
            for (let i = 0; i < this.state.processMaster.length; i++) {
                if (this.state.processMaster[i].ID === this.state.updatedProcessID) {
                    this.state.processMaster[i].StepIndex = this.state.stepIndex;
                    this.state.processMaster[i].ProcessUID = this.state.processUID;
                    this.state.processMaster[i].OperatorAssigned = this.state.requestorName;
                    this.state.processMaster[i].DurationPerBatch = this.state.durationPerBatch;
                    this.state.processMaster[i].EquipmentUID = this.state.equipmentUID;
                    this.state.processMaster[i].EquipmentName = this.state.equipmentName;
                    this.state.processMaster[i].EquipmentChangePart = this.state.equipmentChangePart;
                    this.state.processMaster[i].RequiresFlexibleRoom = this.state.reqFlexibleRoom;
                    this.state.processMaster[i].RoomUID = this.state.roomUID;
                    this.state.processMaster[i].RoomLocation = this.state.roomLocation;
                    this.state.processMaster[i].RoomNumber = this.state.roomNumber;
                }
            }
        } else {
            this.state.processMaster.push({
                "ProcessUID": this.state.processUID,
                "OperatorAssigned": this.state.requestorName,
                "StepIndex": this.state.stepIndex,
                "DurationPerBatch": this.state.durationPerBatch,
                "EquipmentUID": this.state.equipmentUID,
                "EquipmentName": this.state.equipmentName,
                "EquipmentChangePart":this.state.equipmentChangePart,
                "RequiresFlexibleRoom": this.state.reqFlexibleRoom,
                "RoomUID": this.state.roomUID,
                "RoomLocation": this.state.roomLocation,
                "RoomNumber": this.state.roomNumber,
                "Id": this.state.selectedProcessID
            });
        }

        this.state.updateProcess = false;
        this._setProcess(this.state.processMaster);
        localStorage.removeItem('ProcessBookings');
        localStorage.setItem("ProcessBookings", JSON.stringify(this.state.processMaster));

        this.setState({
            processUID: "",
            stepIndex: 0,
            durationPerBatch: 0,
            equipmentUID: "",
            equipmentName: "",
            equipmentChangePart: "",
            reqFlexibleRoom: "",
            roomUID: "",
            roomLocation: "",
            roomNumber: "",
            selectedOption: "",
            requestorName: 0
        });

    }
    _setProcess(processMaster) {
        let userRoles = getUserRoles();
        let hasAdminRole = userRoles.indexOf("Admin") > -1;
        this.setState({ isAdmin: hasAdminRole, process: processMaster, loading: false, showAllProcess: true, showAddEditProcess: false, showConfirmationDialog: false, showDeleteConfirmationDialog: false, selectedProcessID: 0 });
    }

    //Sets the Process Detail value initially
    setVal() {

        this.setState({ requestDate: this.state.startDate })
        action = localStorage.getItem('Action');
        routesToRetrieve = localStorage.getItem('ProdctionRoutes');
        decisionToRetrieve = localStorage.getItem('UserFixedDecision');
        storedProcess = JSON.parse(localStorage.getItem("ProcessBookings") || '{}');

        if ((storedProcess !== null && storedProcess !== "" && Object.entries(storedProcess).length !== 0)) {
            this.setState({ process: storedProcess, processMaster: storedProcess, loading: false })
        } else if (action === "Add") {
            this.setState({ process: [], loading: false })
        } else {
            this.setState({ loading: false });
        }
    }


    //Deletes the Process details from the Local Storage
    _deleteProcess() {
        for (let i = 0; i < this.state.processMaster.length; i++) {
            if (this.state.processMaster[i].Id === this.state.deletedProcessID) {
                this.state.processMaster.splice(i, 1);
            }
        }

        this._setProcess(this.state.processMaster);
        localStorage.removeItem('ProcessBookings');
        localStorage.setItem("ProcessBookings", JSON.stringify(this.state.processMaster));

        this.setState({
            processUID: "",
            requestorName: undefined,
            stepIndex: undefined,
            durationPerBatch: undefined,
            equipmentUID: "",
            equipmentName: "",
            equipmentChangePart:"",
            reqFlexibleRoom:"",
            roomUID: "",
            roomLocation: "",
            roomNumber: "",
        });
    }

    async getAllEquipmentData() {
        const response = await fetch('Equipments', {
            method: 'get',
            headers: new Headers({
                'Authorization': 'Bearer ' + accessToken
            })
        });
        if (response.ok) {
            const data = await response.json();
            let userRoles = getUserRoles();
            let hasAdminRole = userRoles.indexOf("Admin") > -1;
            this.setState({ isAdmin: hasAdminRole, allEquipments: data });
        }
        else {
            this.setState({ isAdmin: false, allEquipments: [] });
        }
    }

    async getAllRoomsData() {
        const response = await fetch('Rooms', {
            method: 'get',
            headers: new Headers({
                'Authorization': 'Bearer ' + accessToken
            })
        });
        if (response.ok) {
            const data = await response.json();
            let userRoles = getUserRoles();
            let hasAdminRole = userRoles.indexOf("Admin") > -1;
            this.setState({ isAdmin: hasAdminRole, allRooms: data });
        }
        else {
            this.setState({ isAdmin: false, allRooms: [] });
        }
    }

    async _getRoomLocationKeywords() {
        const response = await fetch('Keywords/Room Location', {
            method: 'get',
            headers: new Headers({
                'Authorization': 'Bearer ' + accessToken
            })
        });
        if (response.ok) {
            const roomLocationData = await response.json();
            this.setState({ roomLocationKeywords: roomLocationData });
        }
        else {
            this.setState({ roomLocationKeywords: [] });
        }
    }
    async _getEquipmentType() {
        const response = await fetch('Keywords/Equipment Type', {
            method: 'get',
            headers: new Headers({
                'Authorization': 'Bearer ' + accessToken
            })
        });
        if (response.ok) {
            const equipmentType = await response.json();
            this.setState({ equipmentTypeKeywords: equipmentType });
            console.log(this.state.equipmentTypeKeywords)
        }
        else {
            this.setState({ equipmentTypeKeywords: [] });
            console.log(this.state.equipmentTypeKeywords)
        }
    }
}