import React, { Component } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import './../custom.css';
import { AncillariesBooking } from './AncillariesBooking';
import { ToolingBooking } from './ToolingBooking';
import { ProcessBooking } from './ProcessBooking';
import { ReviewBooking } from './ReviewBooking';
import { SupplyRequirementBooking } from './SupplyRequirementBooking';
import { AdditionalInformation } from './AdditionalInformation';

export class BookingRequest extends Component {

    //Rendering the Multiple Tabs Based on the Booking Request
    render() {
        return <div>
        <Tabs>
            <TabList>
                <Tab>Supply Requirement   
                </Tab>
                <Tab>Additional Information
                </Tab>
                <Tab>Process
                </Tab>
                <Tab>Tooling                   
                </Tab>
                <Tab>Ancillary                   
                </Tab>
                <Tab>Review/Submit                    
                </Tab>
            </TabList>
            <TabPanel>
                <SupplyRequirementBooking  history= {this.props.history}></SupplyRequirementBooking>
                </TabPanel>
                <TabPanel>
                    <AdditionalInformation history={this.props.history}></AdditionalInformation>
                </TabPanel>

            <TabPanel>
                <ProcessBooking  history= {this.props.history}></ProcessBooking>
            </TabPanel>
         
                <TabPanel>
                    <ToolingBooking history={this.props.history}></ToolingBooking>
            </TabPanel>
          
            <TabPanel>
                <AncillariesBooking  history= {this.props.history}></AncillariesBooking>
            </TabPanel>
            <TabPanel>
                <ReviewBooking history= {this.props.history}></ReviewBooking>
            </TabPanel>
        </Tabs>
    </div>    
    }
}