import React, { Component } from 'react';
import { Route, Router, Switch } from 'react-router';
import { Layout } from './components/Layout';
import { FetchData } from './components/FetchData';
import { EquipmentInventory } from './components/EquipmentInventory';
import { KeywordInventory } from './components/KeywordInventory';
import { RoomInventory } from './components/RoomInventory';
import { AncillariesInventory } from './components/AncillariesInventory';
import { ToolingInventory } from './components/ToolingInventory';
import { NonSMTDemand } from './components/NonSMTDemand';
import { BookingRequest } from './components/BookingRequest';
import { BookingManagement } from './components/BookingManagement';
import { NotFound } from './components/NotFound';
import { WithAuthenticate } from './msal/MsalAuthProvider';
import { Template } from './components/Template';
import './custom.css'
import { EquipmentChangePart } from './components/EquipmentChangePart';
import { Landing } from './components/Landing';



class RootApp extends Component {
    render() {
        return (
            <Layout>
                <Switch>
                    <Route exact path='/' component={Landing} />
                    <Route exact path='/inventory-equipments' component={EquipmentInventory} />
                    <Route exact path='/inventory-keywords' component={KeywordInventory} />
                    <Route exact path='/inventory-rooms' component={RoomInventory} />
                    <Route exact path='/inventory-ancillaries' component={AncillariesInventory} />
                    <Route exact path='/inventory-changeparts' component={EquipmentChangePart} />
                    <Route exact path='/inventory-tooling' component={ToolingInventory} />
                    <Route exact path='/non-smt-demand' component={NonSMTDemand} />
                    <Route path='/fetch-data' component={FetchData} />
                    <Route path='/booking-request' component={BookingRequest} />
                    <Route path='/booking-management' component={BookingManagement} />
                    <Route path='/booking-history' component={Template} />
                    <Route component={NotFound} />
                </Switch>
            </Layout>

        );
    }
}
export const App = WithAuthenticate(RootApp);

