import React, { Component } from 'react';
import './../custom.css';
import SpaceModal from '@gsk-space-components/space-modal'
import { ThemeProvider, OutlinedInput, TextField } from '@material-ui/core';
import { SpaceAutocomplete } from '@gsk-space-components/autocomplete';
import { msalAuth, getAccessToken, getUserRoles, getUserName } from '../msal/MsalAuthProvider';
import { parseJSON } from 'jquery';
import TableContainer from "./TableContainer";
import { Container } from "reactstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { jsPDF } from "jspdf";
import 'jspdf-autotable';

let accessToken = undefined;
export class NonSMTDemand extends Component {
    static displayName = NonSMTDemand.name;

    static demandTypes = ["Maintenance", "Training", "Validation", "Others"];

    //Default Constructor
    constructor(props) {
        super(props);
        this.state = {
            nonSMTDemands: [],
            showAllDemands: false,
            showAddEditDemand: false,
            selectedDemandID: 0,
            selectedDemandType: "",
            selectedDemandRequestor: "",
            filteredRequestors: [],
            showSearchLoading: false,
            enteredComment: "",
            deletionComment: "",
            loading: true,
            showAlert: false,
            showConfirmationDialog: false,
            showDeleteConfirmationDialog: false,
            showDemandTypeError: false,
            showRequestorError: false,
            showCommentError: false
        };
        this._submitNonSMTDemand = this._submitNonSMTDemand.bind(this);
        this._validateDemandInput = this._validateDemandInput.bind(this);
        this._deleteNonSMTDemand = this._deleteNonSMTDemand.bind(this);
    }

    //Component Did Mount Logic
    async componentDidMount() {
        const accessTokenRequest = {
            scopes: ["api://beefd1ec-7166-4ef5-a037-80d953594bbf/access_as_user"]
        }
        var authRes;
        try {
            authRes = await msalAuth.acquireTokenSilent(accessTokenRequest);
            accessToken = authRes.accessToken
        }
        catch (error) {
            console.log("AquireTokenSilent failure");
            authRes = await msalAuth.acquireTokenPopup(accessTokenRequest);
            accessToken = authRes.accessToken
        }

        this.getSinequaToken();
        this.populateNonSMTData();
    }

    //Exports the PDF Report
    exportPDF = () => {
        const unit = "pt";
        const size = "A4"; // Use A1, A2, A3 or A4
        const orientation = "portrait"; // portrait or landscape

        const marginLeft = 40;
        const doc = new jsPDF(orientation, unit, size);
        doc.setFontSize(15);

        const title = "Non-SMT Demand Report";
        const headers = [["Reference #", "Demand Type", "Requestor", "Comments"]];
        const data = this.state.nonSMTDemands.map(nsd => [nsd.NSRNumber, nsd.DemandType, nsd.RequestorFirstName + " " + nsd.RequestorLastName, nsd.Comments]);

        let content = {
            startY: 50,
            head: headers,
            body: data
        };

        doc.text(title, marginLeft, 40);
        doc.autoTable(content);
        doc.save("Non-SMT Demand Report.pdf")
    }

    //Renders the Page based on New/Edit Request
    render() {
        return (
            <div>
                <h2 id="tabelLabel" >Non-SMT Demand</h2>
                {this.state.loading && <p><gsk-circular-progress> </gsk-circular-progress></p>}
                {!this.state.loading && this.state.showAllDemands &&
                    <div>
                        <div style={{ textAlign: "right" }}>
                            <button title="Create Non-SMT Demand" className={"btn-add"} onClick={() => { this.setState({ showAllDemands: false, showAddEditDemand: true, selectedDemandID: 0, selectedDemandType: "", selectedDemandRequestor: "", enteredComment: "" }); }}>New Non-SMT Demand</button>
                            <button onClick={this.exportPDF} title="Download PDF" className={"btn-add"} >Download PDF</button>
                        </div>
                        <div className={"rootContent"}>{this.renderDemandsTable(this.state.nonSMTDemands)}</div>
                    </div>}
                {!this.state.loading && this.state.showAddEditDemand && <div>{this.renderNewDemandScreen()}</div>}
                {this.state.showConfirmationDialog && <SpaceModal
                    open={this.state.showConfirmationDialog}
                    titleText="Save Non-SMT Demand"
                    body="Would you like to save this Demand?"
                    mainButtonLabel="Yes"
                    secondaryButtonLabel="No"
                    mainButtonFunction={this._submitNonSMTDemand}
                    secondaryButtonFunction={() => { this.setState({ showAddEditDemand: false, showAllDemands: true, showConfirmationDialog: false }) }}
                    id="confirm-save-demand"
                />}
                {this.state.showDeleteConfirmationDialog && <SpaceModal
                    open={this.state.showDeleteConfirmationDialog}
                    titleText="Delete Non-SMT Demand"
                    body={<div>Would you like to delete this Non-SMT Demand?</div>}
                    mainButtonLabel="No"
                    secondaryButtonLabel="Yes"
                    secondaryButtonFunction={this._deleteNonSMTDemand}
                    mainButtonFunction={() => { this.setState({ showAllDemands: true, showDeleteConfirmationDialog: false }) }}
                    id="deleteNonSMTDemandDialog"
                />}
                {this.state.showAlert && <SpaceModal
                    open={this.state.showAlert}
                    titleText="Error Occured"
                    body="Error Occured. Please Contact the Administrator"
                    mainButtonLabel="Close"
                    mainButtonFunction={() => { this.setState({ showAlert: false }) }}
                    id="alertBox"
                />}
            </div >
        );
    }

    //Renders the existing data from Inventory
    renderDemandsTable(nonSMTDemands) {
        const data=this.state.nonSMTDemands; 
         const columns=[
             { Header: "Reference #",
             accessor: "NSRNumber"},
             { Header: "Demand Type",
             accessor: "DemandType"},
             { Header: "Requestor",
             accessor: "RequestorName"},
             { Header: "Comments",
             accessor: "Comments"},

             {   
                 width: 300,
                 Header: "Action",
                 accessor: "name",
                 Cell: (data ) => (
                     
                     <div>
                         <button title="Edit Demand" className={"btn-edit"} onClick={() => {
                                    this.setState({
                                        showAllDemands: false, showAddEditDemand: true, selectedDemandID: data.row.original.ID, selectedDemandType: data.row.original.DemandType, enteredComment: data.row.original.Comments,
                                        selectedDemandRequestor: data.row.original.RequestorName
                                     });
                         }}>Edit</button>
                         <button title="Delete Demand" className={"btn-delete"} onClick={() => {
                             this.setState({
                                 showDeleteConfirmationDialog: true,
                                 selectedDemandID: data.row.original.ID
                             });
                         }}>Delete</button>
                 </div>
                
                   
                 )
                 
               }
     ];
        return (
            <div>
                 {columns!==undefined}
                <Container style={{ marginTop: 50 }}>
                <TableContainer columns={columns} data={data} />
                </Container>
            </div>
        );
    }

    //Renders the New Demand Screen
    renderNewDemandScreen() {
        return (<div>
            <gsk-card image="/imagess/documentation.png" elevation="6">
                <div slot="content" className="gsk-card__content">
                    <div className="formcontainer">
                        <div className="formrow">
                            <div className="col-25">
                                <label >Demand Type</label>
                            </div>
                            <div className="col-75">
                                <SpaceAutocomplete
                                    id="nsr-demandtype"
                                    options={NonSMTDemand.demandTypes}
                                    getOptionLabel={(option) => option}
                                    fullWidth={true}
                                    placeholder={"Select Demand Type.."}
                                    value={this.state.selectedDemandType}
                                    onChange={(_event, newValue) => { this.setState({ selectedDemandType: newValue, showDemandTypeError: (newValue == undefined) }) }}
                                    error={this.state.showDemandTypeError}
                                    multiple={false}
                                />
                            </div>
                        </div>
                        <div className="formrow">
                            <div className="col-25">
                                <label >Requestor</label>
                            </div>
                            <div className="col-75">
                                <SpaceAutocomplete
                                    id="nsr-requestor"
                                    options={this.state.filteredRequestors}
                                    getOptionLabel={(option) => option}
                                    loading={this.state.showSearchLoading}
                                    fullWidth={true}
                                    placeholder={"Select Requestor.."}
                                    value={this.state.selectedDemandRequestor}
                                    error={this.state.showRequestorError}
                                    onInputChange={(_event, newValue) => { this._searchRequestor(newValue); this.setState({ filteredRequestors: []}) }}
                                />
                            </div>
                        </div>
                        <div className="formrow">
                            <div className="col-25">
                                <label >Comments</label>
                            </div>
                            <div className="col-75">
                                <OutlinedInput fullWidth={true} multiline={true} rows={3} style={{ backgroundColor: "white" }} placeholder={"Enter Comment.."} value={this.state.enteredComment} onChange={(e) => { this.setState({ enteredComment: e.target.value }); }}></OutlinedInput>
                            </div>
                        </div>
                        <div className="formrow">
                            <button className={"submitBtn"} onClick={this._validateDemandInput}>Submit</button>
                            <button className={"cancelBtn"} onClick={() => {
                                this.setState({
                                    showAddEditDemand: false,
                                    showAllDemands: true,
                                    showDemandTypeError: false,
                                    showRequestorError: false
                                });
                            }}>Cancel</button>
                        </div>
                    </div>
                </div>
            </gsk-card>
        </div >);
    }

    //Searches the GSK Active Directory
    _searchRequestor(newValue) {
        if (newValue == "") {
            this.setState({ showRequestorError: true, filteredRequestors: [] });
        }
        else {
            this.setState({ showSearchLoading: true, selectedDemandRequestor: newValue, showRequestorError: false });
            var myHeaders = new Headers();
            myHeaders.append("Authorization", "Bearer " + sessionStorage.getItem("peoplesearch.accesstoken"));
            
            let queryParams = {
                "name": "enterprise-search-query",
                "text": newValue,
                "select": "[{\"expression\":\"treepath: (`People`: `/People/*`)\",\"facet\":\"Treepath\"}]"
            }

            let requestParams = {
                "app" : "EnterpriseSearchApp",
                "query" : queryParams
            }

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                redirect: 'follow',
                body: JSON.stringify(requestParams)
            };
            fetch("https://dev.api.gsk.com/tools/search/sinequa/query" , requestOptions)
                .then(response => response.text())
                .then(result => {
                    let formattedResult = parseJSON(result);
                    let options = [];
                    if (formattedResult.methodresult) {
                        for (var i in formattedResult.records) {
                            let entries = formattedResult.records[i];
                            options.push(entries.title);
                        }
                    }
                    this.setState({ filteredRequestors: options});
                    this.setState({ showSearchLoading: false });

                })
                .catch(error => console.log('error', error));
        }
    }

    //Deletes the Non SMT Demand Requests
    _deleteNonSMTDemand() {
        fetch('NonSMTDemand/' + this.state.selectedDemandID, {
            method: 'DELETE',
            headers: new Headers({
                'Authorization': 'Bearer ' + accessToken
            }),
        }).then(_ => {
            window.location.reload();
        }).catch(console.log);
    }

    //Populates the Non SMT Demand Data
    async populateNonSMTData() {
        const response = await fetch('NonSMTDemand', {
            method: 'get',
            headers: new Headers({
                'Authorization': 'Bearer ' + accessToken
            })
        });
        if (response.ok) {
            const data = await response.json();
            this.setState({ nonSMTDemands: data, loading: false, showAllDemands: true, showAddEditDemand: false, showConfirmationDialog: false, showDeleteConfirmationDialog: false, selectedDemandID: 0 });
        }
        else {
            this.setState({ nonSMTDemands: [], loading: false, showAllDemands: true, showAddEditDemand: false, showConfirmationDialog: false, showDeleteConfirmationDialog: false, selectedDemandID: 0 });
        }
    }

    //Gets the Sinequa Token for People Search
    async getSinequaToken() {
        const response = await fetch('SinequaToken', {
            method: 'get',
            headers: new Headers({
                'Authorization': 'Bearer ' + accessToken
            })
        });
        if (response.ok) {
            const data = await response.text();
            let authResp = parseJSON(data);
            sessionStorage.setItem("peoplesearch.accesstoken", authResp.access_token);
            this.setState({
                loading: false
            });
        }
        else {
            this.setState({
                loading: false
            });
        }
    }

    //Validates the Input provided by User
    _validateDemandInput() {
        let canSave = true;
        if (!this.state.selectedDemandType) {
            this.setState({ showDemandTypeError: true });
            canSave = false;
        }
        if (!this.state.selectedDemandRequestor) {
            this.setState({ showRequestorError: true });
            canSave = false;
        }
        if (canSave) {
            this.setState({ showDemandTypeError: false, showRequestorError: false, showConfirmationDialog: true });
        }
    }

    //Submits the Non SMT Demand
    _submitNonSMTDemand() {
        let selectedDemandType = this.state.selectedDemandType;
        let requestor = this.state.selectedDemandRequestor;
        let demand = {
            "DemandType": selectedDemandType,
            "RequestorName": requestor,
            "Comments": this.state.enteredComment,
            "ID": this.state.selectedDemandID
        };

        this.setState({
            selectedDemandType: "",
            enteredComment: "",
            selectedDemandRequestor: ""
        });

        this._postNonSMTDemand(demand);
    }

    //Creates the Non SMT Demand
    async _postNonSMTDemand(demand) {
        const requestOptions = {
            method: 'POST',
            headers: new Headers({
                'Authorization': 'Bearer ' + accessToken,
                'Content-Type': 'application/json'
            }),
            body: JSON.stringify(demand)
        };
        await fetch("NonSMTDemand", requestOptions).then((response) => {
            if (response.ok) {
                this.populateNonSMTData();
            } else {
                this.setState({
                    showAlert: true
                });
                this.populateNonSMTData();
            }
        });
    }
}