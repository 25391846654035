import { Button } from 'bootstrap';
import React, { Component, useMemo } from 'react';
import './../custom.css';
import SpaceModal from '@gsk-space-components/space-modal';
import { ThemeProvider, OutlinedInput, TextField } from '@material-ui/core';
import { msalAuth, getUserRoles } from '../msal/MsalAuthProvider';
import TableContainer from "./TableContainer";
import { Container } from "reactstrap"
import "bootstrap/dist/css/bootstrap.min.css";
import { jsPDF } from "jspdf";
import 'jspdf-autotable';

let accessToken = undefined;

export class EquipmentChangePart extends Component {
    static displayName = EquipmentChangePart.name;

    //Default Constructor
    constructor(props) {
        super(props);
        
        this.state = {
            isAdmin: false,
            equipmentUID: "",
            equipmentName: "",
            changePartUID: "",
            changePartName: "",
            showChangePartUIDError: "",
            showChangePartNameError: "",
            selectedID: 0,
            showAlert: false,
            showAllEquipmentChangeParts: false,
            showAddEditEquipmentChangePart: false,
            showConfirmationDialog: false,
            showDeleteConfirmationDialog: false,
            deletionComment: "",
            showCommentError: false,
            loading: true,
            columns: [],
            allEquipmentChangeParts: [],
            changePartMaster: [],
            headers: "",
            accessor: ""
        };

        this._submitChangePart = this._submitChangePart.bind(this);
        this._deleteChangePart = this._deleteChangePart.bind(this);
        this._validateChangePartInput = this._validateChangePartInput.bind(this);
    }

    //Component Did Mount Logic
    async componentDidMount() {
        const accessTokenRequest = {
            scopes: ["api://beefd1ec-7166-4ef5-a037-80d953594bbf/access_as_user"]
        }
        var authRes;
        try {
            authRes = await msalAuth.acquireTokenSilent(accessTokenRequest);
            accessToken = authRes.accessToken
        }
        catch (error) {
            console.log("AquireTokenSilent failure");
            authRes = await msalAuth.acquireTokenRedirect(accessTokenRequest);
            accessToken = authRes.accessToken
        }
        this.populateEquipmentChangePartData();
    }

    //renders the Equipment Change Part data from Inventory
    renderEquipmentsTable(equipmentData) {
        const data = this.state.allEquipmentChangeParts;
        const columns = [
            {
                Header: "Equipment UID",
                accessor: "EquipmentUid"
            },
            {
                Header: "Equipment Name",
                accessor: "EquipmentName",
            },
            {
                Header: "Change Part UID",
                accessor: "ChangePartUid"
            },
            {
                Header: "Change Part Name",
                accessor: "ChangePartName",
            },
            {
                width: 300,
                Header: "Action",
                accessor: "name",
                disableFilters: false,
                autoResetFilters: false,
                Cell: (data) => (

                    <div>
                        <button title="Edit Equipment" className={"btn-edit"} onClick={() =>
                            this.setState({

                                showAllEquipmentChangeParts: false,
                                showAddEditEquipmentChangePart: true,
                                equipmentUID: data.row.original.EquipmentUid,
                                equipmentName: data.row.original.EquipmentName,
                                changePartUID: data.row.original.ChangePartUid,
                                changePartName: data.row.original.ChangePartName,
                                selectedID: data.row.original.Id
                            })
                        }
                        >
                            Edit </button>
                        <button title="Delete Equipment" className={"btn-delete"} onClick={() => {
                            this.setState({
                                showDeleteConfirmationDialog: true,
                                selectedID: data.row.original.Id
                            });
                        }}>Delete</button>
                    </div>
                )
            }
        ];
        return (
            <div id="test">
                {columns !== undefined}
                <Container style={{ marginTop: 50 }}>
                    <TableContainer columns={columns} data={data} />
                </Container>

            </div>
        );
    }

    //Renders the new Equipment Change Part Details
    renderNewEquipmentScreen() {
        return (
            <div>
            <div className="formcontainer">
                <div className="formrow">
                    <div className="col-25">
                        <label >Equipment UID</label>
                    </div>
                    <div className="col-75">
                        <OutlinedInput fullWidth={true} required disabled bold value={this.state.equipmentUID}
                        ></OutlinedInput>
                    </div>
                </div>

                <div className="formrow">
                    <div className="col-25">
                        <label >Equipment Name</label>
                    </div>
                    <div className="col-75">
                            <OutlinedInput fullWidth={true} required disabled bold value={this.state.equipmentName}
                        ></OutlinedInput>
                    </div>
                </div>
                
                <div className="formrow">
                    <div className="col-25">
                        <label >Change Part UID</label>
                    </div>
                    <div className="col-75">
                        <OutlinedInput fullWidth={true} required placeholder={"Enter Change Part UID.."} value={this.state.changePartUID} error={this.state.showChangePartUIDError}
                            onChange={(e) => { this.setState({ changePartUID: e.target.value, showChangePartUIDError: (e.target.value === undefined || e.target.value === "") }); }}
                        ></OutlinedInput>
                    </div>
                </div>
                <div className="formrow">
                            <div className="col-25">
                        <label >Change Part Name</label>
                            </div>
                    <div className="col-75">
                        <OutlinedInput fullWidth={true} required placeholder={"Enter Change Part Name.."} value={this.state.changePartName} error={this.state.showChangePartNameError}
                            onChange={(e) => { this.setState({ changePartName: e.target.value, showChangePartNameError: (e.target.value === undefined || e.target.value === "") }); }}
                        ></OutlinedInput>
                    </div>
                        </div>
                               </div>
                <div className="formrow">
                    <button className={"submitBtn"} onClick={this._validateChangePartInput}>Submit</button>
                    <button className={"cancelBtn"} onClick={() => {
                        this.setState({
                            showAddEditEquipmentChangePart: false,
                            showAllEquipmentChangeParts: true,
                            showChangePartUIDError: false,
                            showChangePartNameError: false
                        });
                    }}>Cancel</button>
                </div>
           
        </div >);
    }

    //Renders the page based on New/Edit Request
    render() {
        return (
            <div>
                <h2 id="tabelLabel" >Inventory - Change Part</h2>
                {this.state.loading && <p><gsk-circular-progress> </gsk-circular-progress></p>}
                {!this.state.loading && this.state.showAllEquipmentChangeParts &&
                    <div>
                        {this.state.isAdmin && <div style={{ textAlign: "right" }}>
                        <button title="Create New Change Part" className={"btn-add"} onClick={() => {
                            this.setState({
                                    showAllEquipmentChangeParts: false,
                                    showAddEditEquipmentChangePart: true,
                                    changePartUID: "",
                                    changePartName:""
                                });
                        }}>New Equipment Change Part</button>
                        <button onClick={this._redirectToEquipmentDashboard} title="Equipment Dashboard" className={"btn-add"} >Equipment Dashboard</button>
                    </div>}
                        <div className={"rootContent"}>{this.renderEquipmentsTable(this.state.allEquipmentChangeParts)}</div>
                    </div>
                }
               
                {!this.state.loading && this.state.showAddEditEquipmentChangePart && <div>{this.renderNewEquipmentScreen()}</div>}
                {this.state.showConfirmationDialog && <SpaceModal
                    open={this.state.showConfirmationDialog}
                    titleText="Save Equipment Change Part"
                    body="Would you like to save this Equipment Change Part?"
                    mainButtonLabel="Yes"
                    secondaryButtonLabel="No"
                    mainButtonFunction={this._submitChangePart}
                    secondaryButtonFunction={() => { this.setState({ showAddEditEquipment: false, showAllEquipments: true, showConfirmationDialog: false, showDeleteConfirmationDialog: false }) }}
                    id="saveEquipDialog"
                />}
                {this.state.showDeleteConfirmationDialog && <SpaceModal
                    open={this.state.showDeleteConfirmationDialog}
                    titleText="Delete Equipment Change Part"
                    body={<div><div>Would you like to delete this Equipment Change Part?</div><div>
                        <OutlinedInput fullWidth={true} placeholder={"Enter reason.."} required multiline={true} rows={3} error={this.state.showCommentError}
                            onChange={(e) => { this.setState({ deletionComment: e.target.value, showCommentError: (e.target.value === undefined || e.target.value === "") }); }}
                        ></OutlinedInput>
                    </div></div>}
                    mainButtonLabel="No"
                    secondaryButtonLabel="Yes"
                    secondaryButtonFunction={this._deleteChangePart}
                    mainButtonFunction={() => { this.setState({ showAddEditEquipment: false, showAllEquipments: true, showConfirmationDialog: false, showDeleteConfirmationDialog: false }) }}
                    id="deleteEquipDialog"
                />}
                {this.state.showAlert && <SpaceModal
                    open={this.state.showAlert}
                    titleText="Error Occured"
                    body="Error Occured. Please Contact the Administrator"
                    mainButtonLabel="Close"
                    mainButtonFunction={() => { this.setState({ showAlert: false }) }}
                    id="alertBox"
                />}
            </div>
        );
    }

    //Validates the Input provided by User
    _validateChangePartInput() {
        let canSave = true;

        if (!this.state.changePartUID) {
            this.setState({ showChangePartUIDError: true });
            canSave = false;
        }
        if (!this.state.changePartName) {
            this.setState({ showChangePartNameError: true });
            canSave = false;
        }

        if (canSave) {
            this.setState({
                showChangePartUIDError: false,
                showChangePartNameError: false,
                showConfirmationDialog: true
            });
        }
    }

    //Redirection to Equipment Dashboard
    _redirectToEquipmentDashboard = () => {
        window.location.replace("/inventory-equipments");
    }

    //Submits the Equipment Change Part Details
    _submitChangePart() {
        let changePartMasterDetails = {
            "EquipmentUid": this.state.equipmentUID,
            "EquipmentName": this.state.equipmentName,
            "ChangePartUid": this.state.changePartUID,
            "ChangePartName": this.state.changePartName,
            "Id": Number(this.state.selectedID)
        };

        this._postEquipmentChangePart(changePartMasterDetails);
    }

    //Creates/Updates the Equipment Change part Details
    async _postEquipmentChangePart(changePartData) {
        const requestOptions = {
            method: 'POST',
            headers: new Headers({
                'Authorization': 'Bearer ' + accessToken,
                'Content-Type': 'application/json'
            }),
            body: JSON.stringify(changePartData)
        };
        await fetch("EquipmentsChangePart", requestOptions).then((response) => {
            this.setState({ showConfirmationDialog: false });
            if (response.ok) {
                this.populateEquipmentChangePartData();
            } else {
                this.setState({
                    showAlert: true
                });
                this.populateEquipmentChangePartData();
            }
        })
    }

    //Populate the Equipment Change Part from the Inventory
    async populateEquipmentChangePartData() {

        const urlParams = new URLSearchParams(window.location.search);
        const equipID = urlParams.get('ID');
        const equipName = urlParams.get('Name');
        this.setState({ equipmentUID: equipID, equipmentName: equipName })

        const response = await fetch('EquipmentsChangePart', {
            method: 'get',
            headers: new Headers({
                'Authorization': 'Bearer ' + accessToken
            })
        });
        if (response.ok) {
            const data = await response.json();
            let userRoles = getUserRoles();
            let hasAdminRole = userRoles.indexOf("Admin") > -1;
            this.setState({ isAdmin: hasAdminRole, allEquipmentChangeParts: data.filter(changePart => changePart.EquipmentUid === this.state.equipmentUID), loading: false, showAllEquipmentChangeParts: true, showAddEditEquipmentChangePart: false, showConfirmationDialog: false, showDeleteConfirmationDialog: false });
        }
        else {
            this.setState({ isAdmin: false, allEquipmentChangeParts: [], loading: false, showAllEquipmentChangeParts: true, showAddEditEquipmentChangePart: false, showConfirmationDialog: false, showDeleteConfirmationDialog: false   });
        }
    }

    //Deletes the Equipment Change Part from the Inventory
    _deleteChangePart() {
        if (this.state.deletionComment) {
            fetch("EquipmentsChangePart/" + Number(this.state.selectedID) + "?comment=" + this.state.deletionComment, {
                method: 'DELETE',
                headers: new Headers({
                    'Authorization': 'Bearer ' + accessToken
                })
            }).then((response) => {
                if (response.ok) {
                    this.populateEquipmentChangePartData();
                } else {
                    this.setState({
                        showAlert: true
                    });
                    this.populateEquipmentChangePartData();
                }
            }).catch(console.log);
        }
        else {
            this.setState({ showCommentError: true });
        }
    }

}
