import React, { Component } from 'react';
import { msalConfig } from './MsalConfig';
import { UserAgentApplication } from 'msal';

export const msalAuth = new UserAgentApplication(msalConfig);


export function getAccessToken() {
    if (sessionStorage.getItem("msal.idtoken") !== undefined && sessionStorage.getItem("msal.idtoken") != null) {
        return sessionStorage.getItem("msal.idtoken");
    }
}

export function getUserRoles() {
    if (sessionStorage.getItem("msal.userRoles") !== undefined && sessionStorage.getItem("msal.userRoles") != null) {
        return sessionStorage.getItem("msal.userRoles");
    }
}

export function getUserName() {
    if (sessionStorage.getItem("msal.userName") !== undefined && sessionStorage.getItem("msal.userName") != null) {
        return sessionStorage.getItem("msal.userName");
    }
}


export function WithAuthenticate(HocComponent) {
    return class extends Component {
        constructor(props) {
            super(props);

            this.state = {
                isAuthenticated: false,
                user: {},
                renewIframe: false,
                hasError: false,
                errorMessage: null
            };
        }

        async componentWillMount() {
            msalAuth.handleRedirectCallback(() => {
                // debugger;
                let userAccount = msalAuth.getAccount();
                sessionStorage.setItem("msal.userRoles", JSON.stringify(userAccount.idTokenClaims.roles));
                sessionStorage.setItem("msal.userName", userAccount.idTokenClaims.name);
                this.setState({
                    isAuthenticated: true,
                    user: userAccount
                });
            }, (authErr, accountState) => {  // on fail
                console.log(authErr);

                this.setState({
                    hasError: true,
                    errorMessage: authErr.errorMessage
                });
            });

            if (msalAuth.isCallback(window.location.hash)) {
                this.setState({
                    auth: {
                        renewIframe: true
                    }
                });
                return;
            }


            let userAccount = msalAuth.getAccount();
            if (!userAccount) {
                msalAuth.loginRedirect({ authority: msalConfig.auth.authority, redirectUri: msalConfig.auth.redirectUri });
                return;
            } else {
                sessionStorage.setItem("msal.userRoles", JSON.stringify(userAccount.idTokenClaims.roles));
                sessionStorage.setItem("msal.userName", userAccount.idTokenClaims.name);
                this.setState({
                    isAuthenticated: true,
                    user: userAccount
                });
            }
        }

        onSignIn() {
            msalAuth.loginRedirect({ authority: msalConfig.auth.authority, redirectUri: msalConfig.auth.redirectUri });
        }

        onSignOut() {
            window.location.assign("https://logout.gsk.com");
        }

        render() {
            if (this.state.renewIframe) {
                return <div>hidden renew iframe - not visible</div>;
            }

            if (this.state.isAuthenticated) {
                return <HocComponent auth={this.state} onSignIn={() => this.onSignIn()} onSignOut={() => this.onSignOut()} {...this.props} />;
            }

            if (this.state.hasError) {
                return <div>{this.state.errorMessage}</div>;
            }

            return <div></div>;
        }
    };
}
