export const msalConfig = {
    auth: {
        authority: 'https://login.microsoftonline.com/63982aff-fb6c-4c22-973b-70e4acfb63e6',
        clientId: 'beefd1ec-7166-4ef5-a037-80d953594bbf',
        redirectUri: document.getElementById('root').baseURI,
        //postLogoutRedirectUri: "https://logout.gsk.com",
    },
    cache: {
        cacheLocation: "sessionStorage",
        storeAuthStateInCookie: false,
        secureCookies: false
    }
};