import { Button } from 'bootstrap';
import React, { Component } from 'react';
import SpaceModal from '@gsk-space-components/space-modal'
import SpaceButton from '@gsk-space-components/button';
import SpaceTheme from '@gsk-space-components/space-theme';
import { ThemeProvider, OutlinedInput, TextField } from '@material-ui/core';
import { SpaceAutocomplete } from '@gsk-space-components/autocomplete';
import { Autocomplete } from '@material-ui/lab/Autocomplete';
import { msalAuth, getAccessToken, getUserRoles, getUserName } from '../msal/MsalAuthProvider';
import { CircularProgress } from '@gsk-platforms/gsk-circular-progress';
import { parseJSON } from 'jquery';
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import './../custom.css';

let accessToken = undefined;
let storedSupply = [];
let storedNames = [];
let action = "";
let campaignIDToRetrieve = "";
let count = 1;
let showValidDate = false;
let showValidDueDate = false;
let showValidDropDate = false;
export class AdditionalInformation extends Component {
    static displayName = AdditionalInformation.name;

    //Validate the Submission of Additional Information Details
    onSubmit = () => {

        if (this.state.supply !== "" && this.state.supply !== null) {
            if (Object.entries(this.state.supply).length > 0) {
                this.setState({ showAlert: true });
            } else {
                this.setState({ showAllSupply: false, showAddEditSupply: true, showAlert: false });
            }
        }
    }

    //Change the Date Format to GSK
    changeDateFormat = (date) => {
        let dateFormat = "";
        var day = new Date(date).getDate();
        if (day < 10) {
            day = '0' + day;
        }
        dateFormat = day + '-' + (new Date(date).toLocaleString('default', { month: 'short' })) + '-' + new Date(date).getFullYear();
        return dateFormat.toString();
    }

    //Change the Date Format from Database
    changeToDate = (date) => {
        let newdate = new Date(date);
        return newdate;
    }

    //Default Constructor
    constructor(props) {
        super(props);

        const newdate = new Date();
        this.state = {
            isAdmin: true,
            supply: [],
            supplyMaster: [],
            showAllSupply: true,
            showAddEditSupply: false,
            selectedSupplyID: 0,
            campaignID: "",
            requestorName: [],
            loading: true,
            updateSupply: false,
            updatedCampaignID: "",
            deletedCampaignID: "",
            showConfirmationDialog: false,
            showDeleteConfirmationDialog: false,
            showCampaignIdError: false,
            showRequestorNameError: false,
            deletionComment: "",
            showCommentError: false,
            showAlert: false,
            selectedDemandRequestor: [],
            filteredRequestors: [],
            showSearchLoading: false,
            showRequestorError: false,
            campaignPriority: "",
            batchTypeKeywords: [],
            batchType: "",
            batchScaleKeywords: [],
            batchScale: "",
            noOfbatches: "",
            batchSize: "",
            earliestStartDate: "",
            dueDate: "",
            dropDeaddate: "",
            testingLeadTime: undefined,
            showCampaignPriorityError: false,
            showBatchTypeError: false,
            showBatchScaleError: false,
            showNoOfBatchesError: false,
            showBatchSizeError: false,
            showEarliestStartDateError: false,
            showDueDateError: false,
            showDropDeadDateError: false,
            showTestingLeadTimeError: false,
            loggedInUser: "",

        };

        this._submitSupply = this._submitSupply.bind(this);
        this._deleteSupply = this._deleteSupply.bind(this);
        this.compareDate = this.compareDate.bind(this);
        this.compareDueDate = this.compareDueDate.bind(this);
        this.compareDropDeadDate = this.compareDropDeadDate.bind(this);
        this._validateSupplyInput = this._validateSupplyInput.bind(this);

    }

    //Component Did Mount Logic
    async componentDidMount() {
        const accessTokenRequest = {
            scopes: ["api://beefd1ec-7166-4ef5-a037-80d953594bbf/access_as_user"]
        }
        var authRes;
        try {
            authRes = await msalAuth.acquireTokenSilent(accessTokenRequest);
            accessToken = authRes.accessToken
        }
        catch (error) {
            console.log("AquireTokenSilent failure");
            authRes = await msalAuth.acquireTokenPopup(accessTokenRequest);
            accessToken = authRes.accessToken
        }
        this.setVal();
        this.populateSupplyData();
        this._getBatchScaleKeywords();
        this._getBatchTypeKeywords();

    }

    //Renders the page based on New/Edit Requests
    render() {
        return (
            <div>

                {this.state.loading && <p><gsk-circular-progress> </gsk-circular-progress></p>}
                {!this.state.loading && this.state.showAllSupply &&
                    <div>
                        <div style={{ textAlign: "right" }}>
                            {<button title="Create New Supply" className={"btn-add"} onClick={() => {
                                this.onSubmit();
                            }}>Add Supply</button>}
                        </div>

                        {this.state.showAlert && <SpaceModal
                            open={this.state.showAlert}
                            titleText="Supply Added"
                            body="You have already added Supply Requirement. Please add process."
                            mainButtonLabel="Close"
                            mainButtonFunction={() => { this.setState({ showAllSupply: true, showAlert: false }) }}
                            id="example-3"
                        />}
                        <div className={"rootContent"}>{this.rendersupplyTable(this.state.supply)}</div>
                    </div>}


                {!this.state.loading && this.state.showAddEditSupply && <div>{this.renderNewSupplyScreen()}</div>}
                {this.state.showConfirmationDialog && <SpaceModal
                    open={this.state.showConfirmationDialog}
                    titleText="Save Supply"
                    body="Would you like to save this Supply?"
                    mainButtonLabel="Yes"
                    secondaryButtonLabel="No"
                    mainButtonFunction={this._submitSupply}
                    secondaryButtonFunction={() => { this.setState({ showAddEditSupply: false, showAllSupply: true, showConfirmationDialog: false }) }}
                    id="confirm-submit-supply"
                />}
                {showValidDropDate && <SpaceModal
                    open={showValidDropDate}
                    titleText="Info"
                    body="Drop dead date should be greater than due Date "
                    mainButtonLabel="Close"
                    mainButtonFunction={() => { this.setState({ showAddEditSupply: true }); showValidDropDate = false }}
                    id="example-3"
                />}
                {showValidDueDate && <SpaceModal
                    open={showValidDueDate}
                    titleText="Info"
                    body="Due Date should be greater than Earliest Start Date "
                    mainButtonLabel="Close"
                    mainButtonFunction={() => { this.setState({ showAddEditSupply: true }); showValidDueDate = false }}
                    id="example-3"
                />}

                {showValidDate && <SpaceModal
                    open={showValidDate}
                    titleText="Info"
                    body="Earliest start date should be greater than Request Date "
                    mainButtonLabel="Close"
                    mainButtonFunction={() => { this.setState({ showAddEditSupply: true }); showValidDate = false }}
                    id="example-3"
                />}

                {this.state.showDeleteConfirmationDialog && <SpaceModal
                    open={this.state.showDeleteConfirmationDialog}
                    titleText="Delete Supply"
                    body={<div>Would you like to delete this Supply?</div>}
                    mainButtonLabel="Yes"
                    secondaryButtonLabel="No"
                    mainButtonFunction={this._deleteSupply}
                    secondaryButtonFunction={() => {
                        this.setState({
                            showAddEditSupply: false,
                            showAllSupply: true,
                            showConfirmationDialog: false,
                            showDeleteConfirmationDialog: false
                        })
                    }}
                    id="confirm-delete-supply"
                />}
            </div>
        );
    }

    //Render the existing Additional Information details
    rendersupplyTable(supply) {
        return (
            <table className='table table-striped' aria-labelledby="tabelLabel">
                <thead>
                    <tr>
                        <th>Campaign Priority</th>
                        <th>Batch Type</th>
                        <th>Batch Scale</th>
                        <th>Number of Batches</th>
                        <th>Batch Size</th>
                        <th>Earliest Start Date</th>
                        <th>Due Date</th>
                        <th>Drop Dead Date</th>
                        <th>Testing Lead Time</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {(supply === undefined || supply === null || supply.length === 0) && <tr><td colSpan={19}><div className={"no-record-error"}>No records found</div></td></tr>}
                    {supply.map(supply =>
                        <tr>
                            <td>{supply.Priority}</td>
                            <td>{supply.BatchType}</td>
                            <td>{supply.BatchScale}</td>
                            <td>{supply.NumberOfBatches}</td>
                            <td>{supply.BatchSize}</td>
                            <td>{this.changeDateFormat(supply.EarliestStartDate)}</td>
                            <td>{this.changeDateFormat(supply.DueDate)}</td>
                            <td>{this.changeDateFormat(supply.DropDeadDate)}</td>
                            <td>{supply.TestingLeadTime}</td>

                            {<td><div>
                                <button title="Edit Supply" className={"btn-edit"} onClick={() => {
                                    this.setState({
                                        updateSupply: true,
                                        showAllSupply: false,
                                        showAddEditSupply: true,
                                        updatedCampaignID: supply.CampaignID,
                                        selectedSupplyID: supply.ID,
                                        campaignID: supply.CampaignID,
                                        campaignPriority: supply.Priority,
                                        batchType: supply.BatchType,
                                        batchScale: supply.BatchScale,
                                        noOfbatches: supply.NumberOfBatches,
                                        batchSize: supply.BatchSize,
                                        earliestStartDate: this.changeToDate(supply.EarliestStartDate),
                                        dueDate: this.changeToDate(supply.DueDate),
                                        dropDeaddate: this.changeToDate(supply.DropDeadDate),
                                        testingLeadTime: supply.TestingLeadTime
                                    });
                                }}>Edit</button>
                                <button title="Delete Supply" className={"btn-delete"} onClick={() => {
                                    this.setState({
                                        showDeleteConfirmationDialog: true,
                                        selectedSupplyID: supply.ID,
                                        deletedCampaignID: supply.CampaignID
                                    });
                                }}>Delete</button>
                            </div></td>}
                        </tr>
                    )}
                </tbody>
            </table>
        );
    }

    //Render the new Additional Information Details
    renderNewSupplyScreen() {

        return (<div>
            <gsk-card image="/imagess/documentation.png" elevation="6">
                <div slot="content" className="gsk-card__content">
                    <div className="formcontainer">
                        <div className="formrow">
                            <div className="formrow">
                                <div className="col-25">
                                    <label>Campaign Priority</label>
                                </div>
                                <div className="col-75">
                                    <SpaceAutocomplete
                                        id="basic-test"
                                        options={["Very High", "High", "Average", "Low", "Very Low"]}
                                        getOptionLabel={(option) => option}
                                        fullWidth={true}
                                        placeholder="Select Campaign Priority.."
                                        value={this.state.campaignPriority}
                                        error={this.state.showCampaignPriorityError}
                                        onChange={(_event, newValue) => { this.setState({ campaignPriority: newValue, showCampaignPriorityError: (newValue === undefined || newValue === "") }) }}
                                    />
                                </div>
                            </div>
                            <div className="col-25">
                                <label>Batch Type</label>
                            </div>
                            <div className="col-75">
                                <SpaceAutocomplete
                                    id="basic-test"
                                    //options={["Clinical Manufacturing", "Development", "Stability", "Training", "Not Applicable"]}
                                    options={this.state.batchTypeKeywords.map(itm => itm.Keyword)}
                                    getOptionLabel={(option) => option}
                                    fullWidth={true}
                                    placeholder="Select Batch Type.."
                                    value={this.state.batchType}
                                    error={this.state.showBatchTypeError}
                                    onChange={(_event, newValue) => { this.setState({ batchType: newValue, showBatchTypeError: (newValue === undefined || newValue === "") }) }}
                                />
                            </div>
                        </div>
                        <div className="formrow">
                            <div className="col-25">
                                <label >Batch Scale</label>
                            </div>
                            <div className="col-75">
                                <SpaceAutocomplete
                                    id="batchScale-test"
                                    options={this.state.batchScaleKeywords.map(itm => itm.Keyword)}
                                    getOptionLabel={(option) => option}
                                    fullWidth={true}
                                    placeholder="Select Batch Scale.."
                                    value={this.state.batchScale}
                                    error={this.state.showBatchScaleError}
                                    onChange={(_event, newValue) => { this.setState({ batchScale: newValue, showBatchScaleError: (newValue === undefined || newValue === "") }) }}
                                />
                            </div>
                        </div>
                        <div className="formrow">
                            <div className="col-25">
                                <label >Number of Batches</label>
                            </div>
                            <div className="col-75">
                                <OutlinedInput fullWidth={true} required placeholder={"Enter Number of Batches.."} value={this.state.noOfbatches} error={this.state.showNoOfBatchesError}
                                    onChange={(e) => { this.setState({ noOfbatches: e.target.value, showNoOfBatchesError: (e.target.value === undefined || e.target.value === "") }); }}
                                ></OutlinedInput>
                            </div>
                        </div>
                        <div className="formrow">
                            <div className="col-25">
                                <label >Batch Size</label>
                            </div>
                            <div className="col-75">
                                <OutlinedInput fullWidth={true} required placeholder={"Enter Batch Size.."} value={this.state.batchSize} error={this.state.showBatchSizeError}
                                    onChange={(e) => { this.setState({ batchSize: e.target.value, showBatchSizeError: (e.target.value === undefined || e.target.value === "") }); }}
                                ></OutlinedInput>
                            </div>
                        </div>
                        <div className="formrow">
                            <div className="col-25">
                                <label>Earliest Start Date</label>
                            </div>
                            <div className="col-75">
                                <DatePicker
                                    closeOnScroll={true}
                                    dateFormat="dd-MM-yyyy"
                                    placeholderText="Please select Earliest Start Date"
                                    error={this.state.showEarliestStartDateError}
                                    selected={this.state.earliestStartDate}

                                    onChange={(date) => { this.setState({ earliestStartDate: date, showEarliestStartDateError: (date === undefined || date === "") }); }}
                                />

                            </div>
                        </div>
                        <div className="formrow">
                            <div className="col-25">
                                <label>Due Date</label>
                            </div>
                            <div className="col-75">
                                <DatePicker
                                    closeOnScroll={true}
                                    dateFormat="dd-MM-yyyy"
                                    placeholderText="Please select Due Date"
                                    error={this.state.showDueDateError}
                                    selected={this.state.dueDate}
                                    onChange={(date) => { this.setState({ dueDate: date, showDueDateError: (date === undefined || date === "") }); }}
                                />
                            </div>
                        </div>
                        <div className="formrow">
                            <div className="col-25">
                                <label>Drop Dead Date</label>
                            </div>
                            <div className="col-75">

                                <DatePicker
                                    closeOnScroll={true}
                                    dateFormat="dd-MM-yyyy"
                                    placeholderText="Please select Drop Dead Date"
                                    error={this.state.showDropDeadDateError}
                                    selected={this.state.dropDeaddate}
                                    onChange={(date) => { this.setState({ dropDeaddate: date, showDropDeadDateError: (date === undefined || date === "") }); }}
                                />
                            </div>
                        </div>
                        <div className="formrow">
                            <div className="col-25">
                                <label >Testing Lead Time</label>
                            </div>
                            <div className="col-75">
                                <OutlinedInput fullWidth={true} type="number" required placeholder={"Enter Testing Lead Time.."} value={this.state.testingLeadTime} error={this.state.showTestingLeadTimeError}
                                    onChange={(e) => { this.setState({ testingLeadTime: (e.target.value > -1) ? e.target.value : this.state.testingLeadTime, showTestingLeadTimeError: (!(Number(e.target.value) > -1) && !this.state.showTestingLeadTimeError) }); }}
                                ></OutlinedInput>
                            </div>
                        </div>
                        <div className="formrow">
                            <button className={"submitBtn"} onClick={() => {

                                this.compareDate();
                                this.compareDropDeadDate();
                                this.compareDueDate();
                                this._validateSupplyInput();

                            }}>Add</button>
                            <button className={"cancelBtn"} onClick={() => {
                                this.setState({
                                    showAddEditSupply: false,
                                    showAllSupply: true,
                                    showCampaignIdError: false,
                                    showCampaignPriorityError: false,
                                    showBatchTypeError: false,
                                    showBatchScaleError: false,
                                    showNoOfBatchesError: false,
                                    showBatchSizeError: false,
                                    showEarliestStartDateError: false,
                                    showDueDateError: false,
                                    showDropDeadDateError: false,
                                    showTestingLeadTimeError: false
                                });
                            }}>Cancel</button>
                        </div>
                    </div>
                </div>
            </gsk-card>
        </div >);
    }

    //Populates the Additional Information Details
    async populateSupplyData() {
        const response = await fetch('Supply', {
            method: 'get',
            headers: new Headers({
                'Authorization': 'Bearer ' + accessToken
            })
        });
        if (response.ok) {
            const data = await response.json();
            let userRoles = getUserRoles();
            let hasAdminRole = userRoles.indexOf("Admin") > -1;
            this.setState({ isAdmin: hasAdminRole, supply: data, loading: false, showAllSupply: true, showAddEditSupply: false, showConfirmationDialog: false, showDeleteConfirmationDialog: false, selectedSupplyID: 0 });
        }
        else {
            this.setState({ isAdmin: false, supply: [], loading: false, showAllSupply: true, showAddEditSupply: false, showConfirmationDialog: false, showDeleteConfirmationDialog: false, selectedSupplyID: 0 });
        }
    }

    //Gets the Batch Scale details from Keywords
    async _getBatchScaleKeywords() {
        const response = await fetch('Keywords/Batch Scale', {
            method: 'get',
            headers: new Headers({
                'Authorization': 'Bearer ' + accessToken
            })
        });
        if (response.ok) {
            const batchScaleData = await response.json();
            this.setState({ batchScaleKeywords: batchScaleData });
        }
        else {
            this.setState({ batchScaleKeywords: [] });
        }
    }

    //Gets the Batch Type details from Keywords
    async _getBatchTypeKeywords() {
        const response = await fetch('Keywords/Batch Type', {
            method: 'get',
            headers: new Headers({
                'Authorization': 'Bearer ' + accessToken
            })
        });
        if (response.ok) {
            const batchTypeData = await response.json();
            console.log("batchTypeData", batchTypeData);
            this.setState({ batchTypeKeywords: batchTypeData });
        }
        else {
            this.setState({ batchTypeKeywords: [] });
        }
    }

    //Validates the Input provided by User
    _validateSupplyInput() {
        let canSave = true;

        if (!this.state.campaignPriority) {
            this.setState({ showCampaignPriorityError: true });
            canSave = false;
        }
        if (!this.state.batchType) {
            this.setState({ showBatchTypeError: true });
            canSave = false;
        }
        if (!this.state.batchScale) {
            this.setState({ showBatchScaleError: true });
            canSave = false;
        }
        if (!this.state.noOfbatches) {
            this.setState({ showNoOfBatchesError: true });
            canSave = false;
        }
        if (!this.state.batchSize) {
            this.setState({ showBatchSizeError: true });
            canSave = false;
        }
        if (!this.state.earliestStartDate) {
            this.setState({ showEarliestStartDateError: true });
            canSave = false;
        }
        if (!this.state.dueDate) {
            this.setState({ showDueDateError: true });
            canSave = false;
        }
        if (!this.state.dropDeaddate) {
            this.setState({ showDropDeadDateError: true });
            canSave = false;
        }
        if (!this.state.testingLeadTime.toString()) {
            this.setState({ showTestingLeadTimeError: true });
            canSave = false;
        }
        if (canSave) {
            this.setState({
                showCampaignIdError: false,
                showBatchScaleError: false,
                showCampaignPriorityError: false,
                showBatchTypeError: false,
                showNoOfBatchesError: false,
                showBatchSizeError: false,
                showEarliestStartDateError: false,
                showDueDateError: false,
                showDropDeadDateError: false,
                showTestingLeadTimeError: false,
            });
        }

        this.checkSaveSupply();
    }

    //Checks the validdate details
    checkSaveSupply() {
        if (showValidDate === true || showValidDueDate === true || showValidDropDate === true) {
            this.setState({ showConfirmationDialog: false })
        } else {
            this.setState({ showConfirmationDialog: true });
            showValidDate = false;
            showValidDueDate = false;
            showValidDropDate = false;
        }
    }

    //Date Comparision
    compareDate() {
        var processData = JSON.parse(localStorage.getItem("SupplyBooking") || '{}');
        var CurrentDate = processData.map(itm => itm.RequestDate.toString()).join("||");
        console.log(CurrentDate);
        if (this.state.earliestStartDate < this.changeDateFormat(CurrentDate)) {
            showValidDate = true;
        } else {
            showValidDate = false;
        }

    }

    //Date Comparision
    compareDueDate() {

        if (this.state.dueDate < this.state.earliestStartDate) {
            showValidDueDate = true;

        }
    }

    //Date Comparision
    compareDropDeadDate() {
        if (this.state.dropDeaddate < this.state.dueDate) {
            showValidDropDate = true;
        }
    }

    //Submits the Additional Information
    _submitSupply() {
        if (this.state.updateSupply) {
            this.state.supplyMaster[0].Priority = this.state.campaignPriority;
            this.state.supplyMaster[0].BatchType = this.state.batchType;
            this.state.supplyMaster[0].BatchScale = this.state.batchScale;
            this.state.supplyMaster[0].NumberOfBatches = this.state.noOfbatches;
            this.state.supplyMaster[0].BatchSize = this.state.batchSize;
            this.state.supplyMaster[0].EarliestStartDate = this.changeDateFormat(this.state.earliestStartDate);
            this.state.supplyMaster[0].DueDate = this.changeDateFormat(this.state.dueDate);
            this.state.supplyMaster[0].DropDeadDate = this.changeDateFormat(this.state.dropDeaddate);
            this.state.supplyMaster[0].TestingLeadTime = this.state.testingLeadTime;

        }
        else {
            this.state.supplyMaster.push({
                "Priority": this.state.campaignPriority,
                "BatchType": this.state.batchType,
                "BatchScale": this.state.batchScale,
                "NumberOfBatches": this.state.noOfbatches,
                "BatchSize": this.state.batchSize,
                "EarliestStartDate": this.changeDateFormat(this.state.earliestStartDate),
                "DueDate": this.changeDateFormat(this.state.dueDate),
                "DropDeadDate": this.changeDateFormat(this.state.dropDeaddate),
                "TestingLeadTime": this.state.testingLeadTime,
                "ID": this.state.selectedSupplyID
            });
        }

        this.state.updateSupply = false;
        this._setSupply(this.state.supplyMaster);
        localStorage.removeItem('InformationBooking');
        localStorage.setItem("InformationBooking", JSON.stringify(this.state.supplyMaster));
    }

    _setSupply(supplyMaster) {
        let userRoles = getUserRoles();
        let hasAdminRole = userRoles.indexOf("Admin") > -1;
        this.setState({ isAdmin: hasAdminRole, supply: supplyMaster, loading: false, showAllSupply: true, showAddEditSupply: false, showConfirmationDialog: false, showDeleteConfirmationDialog: false, selectedSupplyID: 0 });
    }

    //Loads the value of Additional Information to local storage
    setVal() {
        action = localStorage.getItem('Action');
        campaignIDToRetrieve = localStorage.getItem('CampaignId');
        storedNames = JSON.parse(localStorage.getItem("InformationBooking") || '{}');

        if ((storedNames !== null && storedNames !== "" && Object.entries(storedNames).length !== 0)) {
            this.setState({ supply: storedNames, supplyMaster: storedNames, loading: false })
        } else if (action === "Add") {

            this.setState({ supply: [], loading: false })
        } else if (action === "Edit") {
            this.setState({ supply: JSON.parse(campaignIDToRetrieve), supplyMaster: JSON.parse(campaignIDToRetrieve) })
            localStorage.removeItem('InformationBooking');
            localStorage.setItem("InformationBooking", JSON.stringify(this.state.supplyMaster));
            this.setState({ loading: false });
        }
    }

    //Deletes the Additional information from the Local Storage
    _deleteSupply() {

        for (let i = 0; i < this.state.supplyMaster.length; i++) {
            this.state.supplyMaster.splice(i, 1);
        }

        this._setSupply(this.state.supplyMaster);
        localStorage.removeItem('InformationBooking');
        localStorage.setItem("InformationBooking", JSON.stringify(this.state.supplyMaster));

        this.setState({
            campaignID: "",
            requestorName: [],
            product_compoundID: "",
            compoundType: "",
            campaignType: "",
            processBy: "",
            includesPlacebo: false,
            safetyBookletno: "",
            occupationHazard: "",
            notation: "",
            campaignPriority: "",
            batchType: "",
            batchScale: "",
            noOfbatches: "",
            batchSize: "",
            earliestStartDate: "",
            dueDate: "",
            dropDeaddate: "",
            testingLeadTime: "",
        });
    }
}