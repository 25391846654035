import { Button } from 'bootstrap';
import React, { Component } from 'react';
import SpaceModal from '@gsk-space-components/space-modal'
import { Link } from 'react-router-dom';
import SpaceButton from '@gsk-space-components/button';
import SpaceTheme from '@gsk-space-components/space-theme';
import { ThemeProvider, OutlinedInput, TextField } from '@material-ui/core';
import { SpaceAutocomplete } from '@gsk-space-components/autocomplete';
import { Autocomplete } from '@material-ui/lab/Autocomplete';
import { msalAuth, getAccessToken, getUserRoles, getUserName } from '../msal/MsalAuthProvider';
import { CircularProgress } from '@gsk-platforms/gsk-circular-progress';
import {Redirect} from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import './../custom.css';
import TableContainer from "./TableContainer";

import { Container } from "reactstrap"
import "bootstrap/dist/css/bootstrap.min.css"

let accessToken = undefined;
let campaignIDToRetrieve = "";
let routesToRetrieve = "";
let decisionToRetrieve = "";

export class Template extends Component {
    static displayName = Template.name;

    //Changes the Date Format to GSK
    changeDateFormat = (date) => {
        let dateFormat = "";
        var day= new Date(date).getDate();
        if(day<10){
            day='0'+day;
        }
        dateFormat =day+'-' +   (new Date(date).toLocaleString('default', { month: 'short' }) )+ '-' +new Date(date).getFullYear() ;
        return dateFormat.toString();
    }


    //Starting Point of Booking Request creation
    handleClick(e, num) {
        this.setState({
            updatedID : num
        }, () => {
            e.preventDefault();
            localStorage.removeItem('CampaignId');
            localStorage.removeItem('UserFixedDecision');
            localStorage.removeItem('ProdctionRoutes');
            localStorage.removeItem('SupplyBooking');
            localStorage.removeItem('ToolingsBooking');
            localStorage.removeItem('Equipments');
            localStorage.removeItem('ProcessBookings');
            localStorage.removeItem('RoomBookings');
            localStorage.removeItem('AncillariesBooking');
            localStorage.removeItem('RoomsBooking');
            localStorage.removeItem('InformationBooking');
            localStorage.removeItem('Action');  
            localStorage.setItem('Action', 'Template');
            localStorage.setItem('CampaignId', JSON.stringify(this.state.booking.filter(booking => booking.Campaign_ID === this.state.updatedID)));
            localStorage.setItem('ProdctionRoutes', JSON.stringify(this.state.process.filter(process => process.Campaign_UID === this.state.updatedID)));
            localStorage.setItem('UserFixedDecision', JSON.stringify(this.state.userFixedDecisions.filter(decision => decision.Campaign_UID === this.state.updatedID)));
            this.loadEditData();
            localStorage.setItem('Page', 'Template');
            this.props.history.push('/booking-request');
        });
    }

    //Loads the template data
    loadEditData() {
        campaignIDToRetrieve = localStorage.getItem('CampaignId');
        routesToRetrieve = localStorage.getItem('ProdctionRoutes');
        decisionToRetrieve = localStorage.getItem('UserFixedDecision');

        let supplyReqArray = JSON.parse(campaignIDToRetrieve);
        supplyReqArray[0].RequestDate = new Date();
        localStorage.removeItem('SupplyBooking');
        localStorage.setItem("SupplyBooking", JSON.stringify(supplyReqArray));
       
        localStorage.removeItem('InformationBooking');
        localStorage.setItem("InformationBooking", JSON.stringify(supplyReqArray));

        //Process Master Storage
        let routesdetails = JSON.parse(routesToRetrieve);
        let decisiondetails = JSON.parse(decisionToRetrieve);
        let combinedProcessDetails = [];
        for (let i = 0; i < decisiondetails.length; i++) {
            combinedProcessDetails.push({
                "ProcessUID": routesdetails[i].Process_UID,
                "OperatorAssigned": decisiondetails[i].No_of_Operator,
                "StepIndex": routesdetails[i].Step_Index,
                "DurationPerBatch": routesdetails[i].Dur_Days_Per_Batch,
                "EquipmentUID": decisiondetails[i].Equipment_Assigned,
                "EquipmentName": decisiondetails[i].Equipent_Name,
                "EquipmentChangePart": decisiondetails[i].ChangePart_Assigned,
                "RequiresFlexibleRoom": decisiondetails[i].Requires_Flexible_Room,
                "RoomUID": decisiondetails[i].Room_Assigned,
                "RoomLocation": decisiondetails[i].Room_Location,
                "RoomNumber": decisiondetails[i].Room_Number,
                "Id": decisiondetails[i].ID
            });
        }

        localStorage.removeItem('ProcessBookings');
        localStorage.setItem("ProcessBookings", JSON.stringify(combinedProcessDetails));

        //Ancillary Master Storage
        for (let i = 0; i < decisiondetails.length; i++) {
            let ancillaryDetails = decisiondetails[i].Ancillary_Assigned.split("||");
            let ancillaryQuantityDetails = decisiondetails[i].Ancillary_Quantity.split("||");
            for (let j = 0; j < ancillaryDetails.length; j++) {
                this.state.selectedAncillaries.push(Number(ancillaryDetails[j]));
                this.state.selectedAncillariesQuantity.push(Number(ancillaryQuantityDetails[j]));
            }
            break;
        }

        let ancillariesInventoryData = this.state.allAncillaries.filter(item => this.state.selectedAncillaries.includes(item.AncillaryID));
        for (let i = 0; i < ancillariesInventoryData.length; i++) {
            let ancillaryDetails = decisiondetails[0].Ancillary_Assigned.split("||");
            let ancillaryQuantityDetails = decisiondetails[0].Ancillary_Quantity.split("||");
            for (let j = 0; j < ancillaryDetails.length; j++) {
                if (Number(ancillaryDetails[j]) === ancillariesInventoryData[i].AncillaryID) {
                    ancillariesInventoryData[i].Quantity = Number(ancillaryQuantityDetails[j]);
                }
            }
            this.state.ancillaries.push(ancillariesInventoryData[i]);
        }

        localStorage.removeItem('AncillariesBooking');
        localStorage.setItem("AncillariesBooking", JSON.stringify(this.state.ancillaries));

        //Toolings Storage
        for (let i = 0; i < decisiondetails.length; i++) {
            let toolingDetails = decisiondetails[i].Tooling_Assigned.split("||");
            for (let j = 0; j < toolingDetails.length; j++) {
                this.state.selectedToolings.push(Number(toolingDetails[j]));
            }
            break;
        }
        this.state.selectedToolings = this.state.allToolings.filter(itm => this.state.selectedToolings.includes(itm.SetNumber));
        this.setState({ toolings: this.state.selectedToolings, toolingMaster: this.state.selectedToolings});
        localStorage.removeItem('ToolingsBooking');
        localStorage.setItem("ToolingsBooking", JSON.stringify(this.state.selectedToolings));
    }

    //Default Constructor
    constructor(props) {
        super(props);
        this.state = {
            isAdmin:true,
            booking:[],
            supplyMaster:[],
            campaignMaster:[],
            process:[],
            bookings: [],
            userFixedDecisions: [],
            selectedAncillaries: [],
            selectedAncillariesQuantity: [],
            ancillaries: [],
            allAncillaries: [],
            allToolings: [],
            toolings: [],
            selectedToolings: [],
            showAllBooking: true,
            loading:true,
            selectedBookingID: 0,
            showDeleteConfirmationDialog: false,
            bookingStatus: "",
            updatedID:""
        };

        this._RemoveTemplate = this._RemoveTemplate.bind(this);
    }

    //Component Mount Logic
    async componentDidMount() {
        const accessTokenRequest = {
            scopes: ["api://beefd1ec-7166-4ef5-a037-80d953594bbf/access_as_user"]
        }
        var authRes;
        try {
            authRes = await msalAuth.acquireTokenSilent(accessTokenRequest);
            accessToken = authRes.accessToken
        }
        catch (error) {
            console.log("AquireTokenSilent failure");
            authRes = await msalAuth.acquireTokenPopup(accessTokenRequest);
            accessToken = authRes.accessToken
        }
       
        this.loadBookingData();
        this._getProcessData();
        this._getUserFixedDecisionData();
        this.getAllAncillariesData();
        this.allToolingData();
    }

    //Renders the page based on New/Edit Screen
    render() {
        return (
            <div>
                <h2 id="tabelLabel" >Booking History</h2>
                {this.state.loading && <p><gsk-circular-progress> </gsk-circular-progress></p>}
                {!this.state.loading && this.state.showAllBooking &&
                    <div>
                        <div className={"rootContent"}>{this.renderBookingTable(this.state.booking)}</div>
                    </div>}
                {this.state.showDeleteConfirmationDialog && <SpaceModal
                    open={this.state.showDeleteConfirmationDialog}
                    titleText="Delete Template"
                    body={<div>Would you like to delete this Template?</div>}
                    mainButtonLabel="No"
                    secondaryButtonLabel="Yes"
                    secondaryButtonFunction={this._RemoveTemplate}
                    mainButtonFunction={() => { this.setState({ showAllBooking: true, showDeleteConfirmationDialog: false }) }}
                    id="deleteEquipDialog"
                />}
                
            </div>
        );
    }

    //Renders the existing Data from Inventory
    renderBookingTable(booking) {
        const data = this.state.booking.filter(itm => itm.Status !== "Cancelled" && itm.Template == true);
        data.sort((a, b) => (a.ID > b.ID) ? 1 : -1);
        const columns=[
            { Header: "Booking Reference Number",
            accessor: "ID"},
            { Header: "Campaign ID",
            accessor: "Campaign_ID"},
            { Header: "Requested By",
            accessor: "RequestorName"},
            { Header: "Request Date",
            accessor:(row)=>this.changeDateFormat(row.RequestDate)},
            {   
                width: 300,
                Header: "Action",
                accessor: "name",
                Cell: (data ) => (
                <div>
                        <button title="Create Booking Request" className={"btn-edit"} onClick={(e) => this.handleClick(e,  data.row.original.Campaign_ID)}>Create</button>
                        <button title="Delete Template" className={"btn-delete"} onClick={() => {
                            this.setState({
                                showDeleteConfirmationDialog: true,
                                selectedBookingID: data.row.original.Campaign_ID
                            });
                        }}>Delete</button>
                </div>
                )
            }
        ];
        
        return (
            <div>
                {columns!==undefined}
                <Container style={{ marginTop: 50 }}>
                <TableContainer columns={columns} data={data} />
                </Container>
            </div>
        );
       
    }

    //Removes the template flag from Booking Request
    _RemoveTemplate() {
        fetch('Booking/RemoveFromTemplates?CampaignIdValue=' + this.state.selectedBookingID, {
            method: 'DELETE',
            headers: new Headers({
                'Authorization': 'Bearer ' + accessToken
            }),
        })
        window.location.reload();
    }

    //Gets all the production Routes related data
    async _getProcessData() {
        const response = await fetch('Routes/GetAllRoutes', {
            method: 'get',
            headers: new Headers({
                'Authorization': 'Bearer ' + accessToken
            })
        });
        if (response.ok) {
            const processData = await response.json();
            this.setState({ process: processData });
        }
        else {
            this.setState({ process: [] });
        }
    }

    //Gets all the User Fixed Decisions related Data
    async _getUserFixedDecisionData() {
        const response = await fetch('Booking/GetAllUserFixedDecision', {
            method: 'get',
            headers: new Headers({
                'Authorization': 'Bearer ' + accessToken
            })
        });
        if (response.ok) {
            const userFixedDecisionData = await response.json();
            this.setState({ userFixedDecisions: userFixedDecisionData });
        }
        else {
            this.setState({ userFixedDecisions: [] });
        }
    }

    //Gets all the Campaign Data
    async loadBookingData() {
        const response = await fetch('Booking/GetAllCampaigns', {
            method: 'get',
            headers: new Headers({
                'Authorization': 'Bearer ' + accessToken
            })
        });
        if (response.ok) {
            const bookingData = await response.json();
            this.setState({ booking: bookingData, loading: false  });
        }
        else {
            this.setState({ booking: [], loading: false  });
        }
    }

    //Gets all the Ancillaries Data
    async getAllAncillariesData() {
        const response = await fetch('Ancillaries', {
            method: 'get',
            headers: new Headers({
                'Authorization': 'Bearer ' + accessToken
            })
        });
        if (response.ok) {
            const data = await response.json();
            let userRoles = getUserRoles();
            let hasAdminRole = userRoles.indexOf("Admin") > -1;
            this.setState({ isAdmin: hasAdminRole, allAncillaries: data });
        }
        else {
            this.setState({ isAdmin: false, allAncillaries: [] });
        }
    }

    //Gets all the tooling data
    async allToolingData() {
        const response = await fetch('Tooling', {
            method: 'get',
            headers: new Headers({
                'Authorization': 'Bearer ' + accessToken
            })
        });
        if (response.ok) {
            const data = await response.json();
            let userRoles = getUserRoles();
            let hasAdminRole = userRoles.indexOf("Admin") > -1;
            this.setState({ isAdmin: hasAdminRole, allToolings: data });
        }
        else {
            this.setState({ isAdmin: false, allToolings: [] });
        }
    }

}
