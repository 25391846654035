import { Button } from 'bootstrap';
import React, { Component } from 'react';
import SpaceModal from '@gsk-space-components/space-modal'
import SpaceButton from '@gsk-space-components/button';
import SpaceTheme from '@gsk-space-components/space-theme';
import { ThemeProvider, OutlinedInput, TextField } from '@material-ui/core';
import { SpaceAutocomplete } from '@gsk-space-components/autocomplete';
import { Autocomplete } from '@material-ui/lab/Autocomplete';
import { msalAuth, getAccessToken, getUserRoles, getUserName } from '../msal/MsalAuthProvider';
import { CircularProgress } from '@gsk-platforms/gsk-circular-progress';
import TableContainer from "./TableContainer";
import { defaultColumn } from 'react-table';
import ReactTable from "react-table";
import { Container } from "reactstrap"
import "bootstrap/dist/css/bootstrap.min.css"
import './../custom.css';
import { jsPDF } from "jspdf";
import 'jspdf-autotable';

let accessToken = undefined;
export class AncillariesInventory extends Component {
    static displayName = AncillariesInventory.name;

    //Default Constructor
    constructor(props) {
        
        super(props);
        this.state = {
            isAdmin: false,
            ancillaries: [],
            allAncillaries: [],
            ancillariesKeywords: [],
            showAllAncillaries: false,
            showAddEditAncillary: false,
            selectedRecordID: undefined,
            selectedAncillaryID: undefined,
            selectedancillary: "",
            selectedquantity: "",
            loading: true,
            showAlert: false,
            showConfirmationDialog: false,
            showDeleteConfirmationDialog: false,
            showAncillaryIDError: false,
            showAncillaryError: false,
            showQuantityError: false,
            deletionComment: "",
            showCommentError: false,
        };

        this._submitAncillary = this._submitAncillary.bind(this);
        this._deleteAncillary = this._deleteAncillary.bind(this);
        this._validateAncillaryInput = this._validateAncillaryInput.bind(this);
    }

    //Exports the PDF of Ancillary Details
    exportPDF = () => {
        const unit = "pt";
        const size = "A4"; // Use A1, A2, A3 or A4
        const orientation = "portrait"; // portrait or landscape

        const marginLeft = 40;
        const doc = new jsPDF(orientation, unit, size);
        doc.setFontSize(15);

        const title = "Ancillaries Report";
        const headers = [["Ancillary ID", "Ancillary", "Quantity"]];
        const data = this.state.ancillaries.map(anc => [anc.AncillaryID, anc.Ancillary, anc.Quantity]);

        let content = {
            head: headers,
            body: data
        };

        doc.text(title, marginLeft, 40);
        doc.autoTable(content);
        doc.save("Ancillaries Report.pdf")
    }

    //Component Did Mount Logic
    async componentDidMount() {
        const accessTokenRequest = {
            scopes: ["api://beefd1ec-7166-4ef5-a037-80d953594bbf/access_as_user"]
        }
        var authRes;
        try {
            authRes = await msalAuth.acquireTokenSilent(accessTokenRequest);
            accessToken = authRes.accessToken
        }
        catch (error) {
            console.log("AquireTokenSilent failure");
            authRes = await msalAuth.acquireTokenPopup(accessTokenRequest);
            accessToken = authRes.accessToken
        }
        this.getAllAncillariesData();
        this.populateAncillariesData();
        this._getAncillariesKeywords();
    }

    //Render the page based on New/Edit Request
    render() {
        return (
            <div>
                <h2 id="tabelLabel" >Inventory - Ancillaries</h2>
                {this.state.loading && <p><gsk-circular-progress> </gsk-circular-progress></p>}
                {!this.state.loading && this.state.showAllAncillaries &&
                    <div>
                        <div style={{ textAlign: "right" }}>
                        {this.state.isAdmin && <button title="Create New Ancillary" className={"btn-add"} onClick={() => { this.setState({ showAllAncillaries: false, showAddEditAncillary: true, selectedRecordID: 0, selectedAncillaryID: 0, selectedancillary: "", selectedquantity: "" }); }}>New Ancillary</button>}
                        <button onClick={this.exportPDF} title="Download PDF" className={"btn-add"} >Download PDF</button>
                        </div>
                        <div className={"rootContent"}>{this.renderancillariesTable(this.state.ancillaries)}</div>
                    </div>}
                {!this.state.loading && this.state.showAddEditAncillary && <div>{this.renderNewAncillaryScreen()}</div>}
                {this.state.showConfirmationDialog && <SpaceModal
                    open={this.state.showConfirmationDialog}
                    titleText="Save Ancillary"
                    body="Would you like to save this Ancillary?"
                    mainButtonLabel="Yes"
                    secondaryButtonLabel="No"
                    mainButtonFunction={this._submitAncillary}
                    secondaryButtonFunction={() => { this.setState({ showAddEditAncillary: false, showAllAncillaries: true, showConfirmationDialog: false }) }}
                    id="confirm-submit-ancillary"
                />}
                {this.state.showDeleteConfirmationDialog && <SpaceModal
                    open={this.state.showDeleteConfirmationDialog}
                    titleText="Delete Ancillary"
                    body={<div><div>Would you like to delete this Ancillary?</div><div>
                        <OutlinedInput fullWidth={true} placeholder={"Enter reason.."} required multiline={true} rows={3} error={this.state.showCommentError}
                            onChange={(e) => { this.setState({ deletionComment: e.target.value, showCommentError: (e.target.value === undefined || e.target.value === "") }); }}
                        ></OutlinedInput>
                    </div></div>}
                    mainButtonLabel="No"
                    secondaryButtonLabel="Yes"
                    secondaryButtonFunction={this._deleteAncillary}
                    mainButtonFunction={() => { this.setState({ showAddEditAncillary: false, showAllAncillaries: true, showConfirmationDialog: false, showDeleteConfirmationDialog: false }) }}
                    id="confirm-delete-ancillary"
                />}
                {this.state.showAlert && <SpaceModal
                    open={this.state.showAlert}
                    titleText="Error Occured"
                    body="Error Occured. Please Contact the Administrator"
                    mainButtonLabel="Close"
                    mainButtonFunction={() => { this.setState({ showAlert: false }) }}
                    id="alertBox"
                />}
            </div>
        );
    }

    //Render the Existing Ancillary Details from the Inventory
    renderancillariesTable(ancillaries) {
        const data = this.state.ancillaries;
        const admin = this.state.isAdmin;
        let columns = [];
        if (admin) {
            columns = [
                {
                    Header: "Ancillary ID",
                    accessor: "AncillaryID"
                },
                {
                    Header: "Ancillary",
                    accessor: "Ancillary"
                },
                {
                    Header: "Quantity",
                    accessor: "Quantity"
                },
                {
                    width: 300,
                    Header: "Action",
                    accessor: "name",
                    Cell: (data) => (

                        <div>
                            <button title="Edit Ancillary" className={"btn-edit"} onClick={() =>
                                this.setState({

                                    showAllAncillaries: false,
                                    showAddEditAncillary: true,
                                    selectedRecordID: data.row.original.ID,
                                    selectedAncillaryID: data.row.original.AncillaryID,
                                    selectedancillary: data.row.original.Ancillary,
                                    selectedquantity: data.row.original.Quantity
                                })
                            }
                            >
                                Edit </button>
                            <button title="Delete Ancillary" className={"btn-delete"} onClick={() => {
                                this.setState({
                                    showDeleteConfirmationDialog: true,
                                    selectedRecordID: data.row.original.ID,
                                    deletedAncillaryID: data.row.original.ID
                                });
                            }}>Delete</button>
                        </div>)

                }
            ];
        } else {
            columns = [
                {
                    Header: "Ancillary ID",
                    accessor: "AncillaryID"
                },
                {
                    Header: "Ancillary",
                    accessor: "Ancillary"
                },
                {
                    Header: "Quantity",
                    accessor: "Quantity"
                }
            ];
        }
        return (
            <div>
                {columns!==undefined}
                <Container style={{ marginTop: 50 }}>
                <TableContainer columns={columns} data={data} />
                </Container>
            </div>
        );
    }

    //Render the new Ancillary Details Screen
    renderNewAncillaryScreen() {
        return (<div>
            <gsk-card image="/imagess/documentation.png" elevation="6">
                <div slot="content" className="gsk-card__content">
                    <div className="formcontainer">
                    <div className="formrow">
                            <div className="col-25">
                                <label >Ancillary ID</label>
                            </div>
                            <div className="col-75">
                            <OutlinedInput name={"Ancillary ID"} type="number" fullWidth={true} placeholder={"Select Ancillary ID.."} error={this.state.showAncillaryIDError} required value={this.state.selectedAncillaryID} onChange={(e) => {
                                    this.setState({ selectedAncillaryID: (Number(e.target.value) && e.target.value > 0) ? e.target.value : this.state.selectedAncillaryID, showAncillaryIDError: (!(Number(e.target.value) && e.target.value > 0) && !this.state.selectedAncillaryID) });
                                }}></OutlinedInput>
                                
                            </div>
                        </div>
                        <div className="formrow">
                            <div className="col-25">
                                <label >Ancillary</label>
                            </div>
                            <div className="col-75">
                                <SpaceAutocomplete
                                    id="basic-test"
                                    options={this.state.ancillariesKeywords.map(itm => itm.Keyword)}
                                    getOptionLabel={(option) => option}
                                    fullWidth={true}
                                    placeholder={"Select Ancillary.."}
                                    value={this.state.selectedancillary}
                                    onChange={(_event, newValue) => { this.setState({ selectedancillary: newValue, showAncillaryError: (newValue == undefined) }) }}
                                    error={this.state.showAncillaryError}
                                    required
                                />
                            </div>
                        </div>
                        <div className="formrow">
                            <div className="col-25">
                                <label >Quantity</label>
                            </div>
                            <div className="col-75">
                                <OutlinedInput name={"Quantity"} type="number" fullWidth={true} placeholder={"Enter Quantity.."} error={this.state.showQuantityError} required value={this.state.selectedquantity} onChange={(e) => {
                                    this.setState({ selectedquantity: (Number(e.target.value) && e.target.value > 0) ? e.target.value : this.state.selectedquantity, showQuantityError: (!(Number(e.target.value) && e.target.value > 0) && !this.state.selectedquantity) });
                                }}></OutlinedInput>
                            </div>
                        </div>
                        <div className="formrow">
                            <button className={"submitBtn"} onClick={this._validateAncillaryInput}>Submit</button>
                            <button className={"cancelBtn"} onClick={() => {
                                this.setState({
                                    showAddEditAncillary: false,
                                    showAllAncillaries: true,
                                    showAncillaryError: false,
                                    showQuantityError: false
                                });
                            }}>Cancel</button>
                        </div>
                    </div>
                </div>
            </gsk-card>
        </div >);
    }

    //Populate the Ancillary Details
    async populateAncillariesData() {
        const response = await fetch('Ancillaries', {
            method: 'get',
            headers: new Headers({
                'Authorization': 'Bearer ' + accessToken
            })
        });
        if (response.ok) {
            const data = await response.json();
            let userRoles = getUserRoles();
            let hasAdminRole = userRoles.indexOf("Admin") > -1;
            this.setState({ isAdmin: hasAdminRole, ancillaries: data, loading: false, showAllAncillaries: true, showAddEditAncillary: false, showConfirmationDialog: false, showDeleteConfirmationDialog: false, selectedAncillaryID: 0 });
        }
        else {
            this.setState({ isAdmin: false, ancillaries: [], loading: false, showAllAncillaries: true, showAddEditAncillary: false, showConfirmationDialog: false, showDeleteConfirmationDialog: false, selectedAncillaryID: 0 });
        }
    }

    //Gets the Ancillary Keywords from Inventory
    async _getAncillariesKeywords() {
        const response = await fetch('Keywords/Ancillary', {
            method: 'get',
            headers: new Headers({
                'Authorization': 'Bearer ' + accessToken
            })
        });
        if (response.ok) {
            const ancillariesData = await response.json();
            this.setState({ ancillariesKeywords: ancillariesData });
        } else {
            this.setState({ ancillariesKeywords: [] });
        }
    }

    //Validates the Input provided by User
    _validateAncillaryInput() {
        let canSave = true;
        if (!this.state.selectedancillary) {
            this.setState({ showAncillaryError: true });
            canSave = false;
        }
        if (!this.state.selectedquantity) {
            this.setState({ showQuantityError: true });
            canSave = false;
        }
        if (canSave) {
            this.setState({ showAncillaryError: false, showQuantityError: false, showConfirmationDialog: true });
        }
    }

    //Submits the Ancillary Details
    _submitAncillary() {
        let ancillary = {
            "Ancillary": this.state.selectedancillary,
            "Quantity": Number(this.state.selectedquantity),
            "AncillaryID": Number(this.state.selectedAncillaryID),
            "ID": Number(this.state.selectedRecordID)
        };

        this._postAncillary(ancillary);
    }

    //Gets all the ancillary Details
    async getAllAncillariesData() {
        const response = await fetch('Ancillaries', {
            method: 'get',
            headers: new Headers({
                'Authorization': 'Bearer ' + accessToken
            })
        });
        if (response.ok) {
            const data = await response.json();
            let userRoles = getUserRoles();
            let hasAdminRole = userRoles.indexOf("Admin") > -1;
            this.setState({ isAdmin: hasAdminRole, allAncillaries: data});
        }
        else {
            this.setState({ isAdmin: false, allAncillaries: []});
        }
    }

    //Creates/Updates the Ancillary Details in the Inventory
    async _postAncillary(ancillary) {
        const requestOptions = {
            method: 'POST',
            headers: new Headers({
                'Authorization': 'Bearer ' + accessToken,
                'Content-Type': 'application/json'
            }),
            body: JSON.stringify(ancillary)
        };
        await fetch("Ancillaries", requestOptions).then((response) => {
            if (response.ok) {
                this.populateAncillariesData();
            } else {
                this.setState({
                    showAlert: true
                });
                this.populateAncillariesData();
            }
        })
    }

    //Deletes the Ancillary Details in the Inventory
    _deleteAncillary() {
        if (this.state.deletionComment) {
            fetch("Ancillaries/" + this.state.selectedRecordID + "?comment=" + this.state.deletionComment, {
                method: 'DELETE',
                headers: new Headers({
                    'Authorization': 'Bearer ' + accessToken
                }),
            }).then(_ => {
                this.populateAncillariesData();
            }).catch(console.log);
        }
        else {
            this.setState({ showCommentError: true });
        }
    }
}