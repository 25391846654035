import { Button } from 'bootstrap';
import React, { Component, useMemo } from 'react';
import { useHistory } from "react-router-dom";
import './../custom.css';
import SpaceModal from '@gsk-space-components/space-modal'
import { SpaceAutocomplete } from '@gsk-space-components/autocomplete';
import { Autocomplete } from '@material-ui/lab/Autocomplete';
import { ThemeProvider, OutlinedInput, TextField } from '@material-ui/core';
import { msalAuth, getUserRoles } from '../msal/MsalAuthProvider';
import { CircularProgress } from '@gsk-platforms/gsk-circular-progress';
import TableContainer from "./TableContainer";
import { defaultColumn } from 'react-table';
import ReactTable from "react-table";
import { Container } from "reactstrap"
import "bootstrap/dist/css/bootstrap.min.css"
import html2canvas from 'html2canvas';
import { jsPDF } from "jspdf";

let accessToken = undefined;

export class EquipmentInventory extends Component {
    static displayName = EquipmentInventory.name;

    //Default Constructor
    constructor(props) {
        super(props);
        this.state = {
            isAdmin: false,
            equipments: [],
            equipmentTypeKeywords: [],
            batchScaleKeywords: [],
            showAllEquipments: false,
            showAddEditEquipment: false,
            showAddChangepartDialog: false,
            showEditEquipment: false,
            selectedEquipmentID: 0,
            equipmentType: "",
            equipmentName: "",
            serialNumber: "",
            Zone: "",
            isLocFixed: "",
            batchScale: '',
            showAlert: false,
            showEquipTypeError: false,
            showEquipNameError: false,
            showSerialNoError: false,
            showZoneError: false,
            showIsLocFixedError: false,
            showBatchScaleError: false,
            showConfirmationDialog: false,
            showDeleteConfirmationDialog: false,
            deletionComment: "",
            showCommentError: false,
            loading: true,
            oselectedpeople: [],
            columns: [],
            headers: "",
            accessor: ""
        };

        this._submitEquipment = this._submitEquipment.bind(this);
        this._deleteEquipment = this._deleteEquipment.bind(this);
        this._validateEquipmentInput = this._validateEquipmentInput.bind(this);
    }

    //Exports the Data as PDF
    exportPDF = () => {
        const unit = "pt";
        const size = "A4"; // Use A1, A2, A3 or A4
        const orientation = "portrait"; // portrait or landscape

        const marginLeft = 40;
        const doc = new jsPDF(orientation, unit, size);
        doc.setFontSize(15);

        const title = "Equipment Report";
        const headers = [["Equipment UID", "Equipment Type", "Equipment Name", "Zone UID", "Batch Scale", "Is Location Fixed?"]];
        const data = this.state.equipments.map(eq => [eq.EquipmentUid, eq.ProcessUid, eq.EquipmentName, eq.ZoneUid, eq.BatchScale, eq.IsLocationFixed === "Y" ? "Yes" : "No"]);

        let content = {
            startY: 50,
            head: headers,
            body: data
        };

        doc.text(title, marginLeft, 40);
        doc.autoTable(content);
        doc.save("Equipment Report.pdf")
    }

    //Component Did Mount Logic
    async componentDidMount() {
        const accessTokenRequest = {
            scopes: ["api://beefd1ec-7166-4ef5-a037-80d953594bbf/access_as_user"]
        }
        var authRes;
        try {
            authRes = await msalAuth.acquireTokenSilent(accessTokenRequest);
            accessToken = authRes.accessToken
        }
        catch (error) {
            console.log("AquireTokenSilent failure");
            authRes = await msalAuth.acquireTokenRedirect(accessTokenRequest);
            accessToken = authRes.accessToken
        }
        this.populateEquipmentData();
        this._getEquipmentTypeKeywords();
        this._getBatchScaleKeywords();
    }

    //Renders the existing Equipment from Inventory
    renderEquipmentsTable(equipments) {
        const data = this.state.equipments;
        const admin = this.state.isAdmin;
        let columns = [];
        if (admin) {
            columns = [
                {
                    Header: "Equipment UID",
                    accessor: "EquipmentUid"
                },
                {
                    Header: "Equipment Type",
                    accessor: "ProcessUid"
                },
                {
                    Header: "Equipment Name",
                    accessor: "EquipmentName",
                },
                {
                    Header: "Zone UID",
                    accessor: "ZoneUid"
                },
                {
                    Header: "Batch Scale",
                    accessor: "BatchScale"
                },
                {
                    Header: "Is Location Fixed?",
                    accessor: (row) => row.IsLocationFixed.trim() === "Y" ? "Yes" : "No"
                },
                {
                    width: 300,
                    Header: "Action",
                    accessor: "name",
                    disableFilters: false,
                    autoResetFilters: false,
                    Cell: (data) => (

                        <div>
                            <button title="Edit Equipment" className={"btn-edit"} onClick={() =>
                                this.setState({

                                    showAllEquipments: false,
                                    showAddEditEquipment: true,
                                    selectedEquipmentID: data.row.original.Id,
                                    equipmentType: data.row.original.ProcessUid,
                                    serialNumber: data.row.original.EquipmentUid,
                                    equipmentName: data.row.original.EquipmentName,
                                    Zone: data.row.original.ZoneUid,
                                    batchScale: data.row.original.BatchScale,
                                    isLocFixed: data.row.original.IsLocationFixed === "Y" ? "Yes" : "No",

                                })
                            }
                            >
                                Edit </button>
                            <button title="Delete Equipment" className={"btn-delete"} onClick={() => {
                                this.setState({
                                    showDeleteConfirmationDialog: true,
                                    selectedEquipmentID: data.row.original.Id,
                                    deletedEquipmentID: data.row.original.EquipmentUid
                                });
                            }}>Delete</button>
                        </div>


                    )

                }
            ];
        } else {
            columns = [
                {
                    Header: "Equipment UID",
                    accessor: "EquipmentUid"
                },
                {
                    Header: "Equipment Type",
                    accessor: "ProcessUid"
                },
                {
                    Header: "Equipment Name",
                    accessor: "EquipmentName",
                },
                {
                    Header: "Zone UID",
                    accessor: "ZoneUid"
                },
                {
                    Header: "Batch Scale",
                    accessor: "BatchScale"
                },
                {
                    Header: "Is Location Fixed?",
                    accessor: (row) => row.IsLocationFixed.trim() === "Y" ? "Yes" : "No"
                }
            ];
        }
        return (
            <div id="test">
                {columns !== undefined}
                <Container style={{ marginTop: 50 }}>
                    <TableContainer columns={columns} data={data} />
                </Container>
            </div>
        );
    }

    //Renders teh New Equipment Screen
    renderNewEquipmentScreen() {
        return (<div>
            {this.state.selectedEquipmentID > 0 &&
                <div style={{ textAlign: "right" }}>
                    <button title="Manage Equipment Change Part" className={"btn-add"} onClick={this._manageEquipmentChangePart}>Manage Equipment Changepart</button>
                </div>
            }   
            <div className="formcontainer">
                <div className="formrow">
                    <div className="col-25">
                        <label >Equipment UID</label>
                    </div>
                    <div className="col-75">
                        <OutlinedInput fullWidth={true} required placeholder={"Enter Equipment UID.."} value={this.state.serialNumber} error={this.state.showSerialNoError}
                            onChange={(e) => { this.setState({ serialNumber: e.target.value, showSerialNoError: (e.target.value === undefined || e.target.value === "") }); }}
                        ></OutlinedInput>
                    </div>
                </div>
                <div className="formrow">
                    <div className="col-25">
                        <label >Equipment Type</label>
                    </div>
                    <div className="col-75">
                        <SpaceAutocomplete
                            id="basic-test"
                            options={this.state.equipmentTypeKeywords.map(itm => itm.Keyword)}
                            getOptionLabel={(option) => option}
                            fullWidth={true}
                            placeholder="Select Equipment Type.."
                            value={this.state.equipmentType}
                            onChange={(_event, newValue) => { this.setState({ equipmentType: newValue, showEquipTypeError: (newValue === undefined || newValue === "") }) }}
                            error={this.state.showEquipTypeError}
                        />
                    </div>
                </div>
                <div className="formrow">
                    <div className="col-25">
                        <label >Equipment Name</label>
                    </div>
                    <div className="col-75">
                        <OutlinedInput fullWidth={true} required placeholder={"Enter Equipment Name.."} value={this.state.equipmentName} error={this.state.showEquipNameError}
                            onChange={(e) => { this.setState({ equipmentName: e.target.value, showEquipNameError: (e.target.value === undefined || e.target.value === "") }); }}
                        ></OutlinedInput>
                    </div>
                </div>

                <div className="formrow">
                    <div className="col-25">
                        <label >Zone UID</label>
                    </div>
                    <div className="col-75">
                        <SpaceAutocomplete
                            id="basic-test"
                            options={["OSD", "Inhaled"]}
                            getOptionLabel={(option) => option}
                            fullWidth={true}
                            placeholder="Select Zone UID.."
                            value={this.state.Zone}
                            error={this.state.showZoneError}
                            onChange={(_event, newValue) => { this.setState({ Zone: newValue, showZoneError: (newValue === undefined || newValue === "") }) }}
                        />
                    </div>
                </div>
                <div className="formrow">
                    <div className="col-25">
                        <label >Batch Scale</label>
                    </div>
                    <div className="col-75">
                        <SpaceAutocomplete
                            id="batchScale-test"
                            options={this.state.batchScaleKeywords.map(itm => itm.Keyword)}
                            getOptionLabel={(option) => option}
                            fullWidth={true}
                            placeholder="Select Batch Scale.."
                            value={this.state.batchScale}
                            error={this.state.showBatchScaleError}
                            onChange={(_event, newValue) => { this.setState({ batchScale: newValue, showBatchScaleError: (newValue === undefined || newValue === "") }) }}
                        />
                    </div>
                </div>
                <div className="formrow">
                    <div className="col-25">
                        <label >Is Location Fixed?</label>
                    </div>
                    <div className="col-75">
                        <SpaceAutocomplete
                            id="basic-test"
                            options={["Yes", "No"]}
                            getOptionLabel={(option) => option}
                            fullWidth={true}
                            placeholder="Please select.."
                            value={this.state.isLocFixed}
                            error={this.state.showIsLocFixedError}
                            onChange={(_event, newValue) => { this.setState({ isLocFixed: newValue, showIsLocFixedError: (newValue === undefined || newValue === "") }) }}
                        />
                    </div>
                </div>
                <div className="formrow">
                    <button className={"submitBtn"} onClick={this._validateEquipmentInput}>Submit</button>
                    <button className={"cancelBtn"} onClick={() => {
                        this.setState({
                            showAddEditEquipment: false,
                            showAllEquipments: true,
                            showEquipTypeError: false,
                            showEquipNameError: false,
                            showSerialNoError: false,
                            showZoneError: false,
                            showIsLocFixedError: false,
                            showBatchScaleError: false
                        });
                    }}>Cancel</button>
                </div>
            </div>
        </div >);
    }

    //Render the Page based on New/Edit Request
    render() {
        return (
            <div>
                <h2 id="tabelLabel" >Inventory - Equipment</h2>
                {this.state.loading && <p><gsk-circular-progress> </gsk-circular-progress></p>}
                {!this.state.loading && this.state.showAllEquipments &&
                    <div>

                        {<div style={{ textAlign: "right" }}>
                        {this.state.isAdmin && <button title="Create New Equipment" className={"btn-add"} onClick={() => {
                            this.setState({
                                showAllEquipments: false,
                                showAddEditEquipment: true,
                                equipmentType: "",
                                equipmentName: "",
                                serialNumber: "",
                                Zone: "",
                                batchScale: "",
                                isLocFixed: "",
                                showBatchScaleError: false
                            });
                        }}>New Equipment</button>}
                        <button onClick={this.exportPDF} title="Download PDF" className={"btn-add"} >Download PDF</button>
                        </div>}
                        <div className={"rootContent"}>{this.renderEquipmentsTable(this.state.equipments)}</div>
                    </div>
                }
                {!this.state.loading && this.state.showAddEditEquipment && <div>{this.renderNewEquipmentScreen()}</div>}
                {this.state.showConfirmationDialog && <SpaceModal
                    open={this.state.showConfirmationDialog}
                    titleText="Save Equipment"
                    body="Would you like to save this Equipment?"
                    mainButtonLabel="Yes"
                    secondaryButtonLabel="No"
                    mainButtonFunction={this._submitEquipment}
                    secondaryButtonFunction={() => { this.setState({ showAddEditEquipment: false, showAllEquipments: true, showConfirmationDialog: false, showDeleteConfirmationDialog: false }) }}
                    id="saveEquipDialog"
                />}
                {this.state.showAddChangepartDialog && <SpaceModal
                    open={this.state.showAddChangepartDialog}
                    titleText="Manage Equipment Change part"
                    body="Would you like to manage the Equipment Change Part for this Equipment?"
                    mainButtonLabel="Yes"
                    secondaryButtonLabel="No"
                    mainButtonFunction={this._manageEquipmentChangePart}
                    secondaryButtonFunction={this._refreshEquipment}
                    id="saveEquipDialog"
                />}
                {this.state.showDeleteConfirmationDialog && <SpaceModal
                    open={this.state.showDeleteConfirmationDialog}
                    titleText="Delete Equipment"
                    body={<div><div>Would you like to delete this Equipment?</div><div>
                        <OutlinedInput fullWidth={true} placeholder={"Enter reason.."} required multiline={true} rows={3} error={this.state.showCommentError}
                            onChange={(e) => { this.setState({ deletionComment: e.target.value, showCommentError: (e.target.value === undefined || e.target.value === "") }); }}
                        ></OutlinedInput>
                    </div></div>}
                    mainButtonLabel="No"
                    secondaryButtonLabel="Yes"
                    secondaryButtonFunction={this._deleteEquipment}
                    mainButtonFunction={() => { this.setState({ showAddEditEquipment: false, showAllEquipments: true, showConfirmationDialog: false, showDeleteConfirmationDialog: false }) }}
                    id="deleteEquipDialog"
                />}
                {this.state.showAlert && <SpaceModal
                    open={this.state.showAlert}
                    titleText="Error Occured"
                    body="Error Occured. Please Contact the Administrator"
                    mainButtonLabel="Close"
                    mainButtonFunction={() => { this.setState({ showAlert: false }) }}
                    id="alertBox"
                />}
            </div>
                   
        );
    }

    //Validates the Input provided by User
    _validateEquipmentInput() {
        let canSave = true;
        if (!this.state.equipmentType) {
            this.setState({ showEquipTypeError: true });
            canSave = false;
        }
        if (!this.state.equipmentName) {
            this.setState({ showEquipNameError: true });
            canSave = false;
        }
        if (!this.state.serialNumber) {
            this.setState({ showSerialNoError: true });
            canSave = false;
        }
        if (!this.state.Zone) {
            this.setState({ showZoneError: true });
            canSave = false;
        }
        if (!this.state.isLocFixed) {
            this.setState({ showIsLocFixedError: true });
            canSave = false;
        }
        if (!this.state.batchScale) {
            this.setState({ showBatchScaleError: true });
            canSave = false;
        }

        if (canSave) {
            this.setState({
                showEquipTypeError: false,
                showEquipNameError: false,
                showSerialNoError: false,
                showZoneError: false,
                showIsLocFixedError: false,
                showBatchScaleError: false,
                showConfirmationDialog: true,

            });
        }
    }

    //Refresh the Equipment Page
    _refreshEquipment = () => {

        this.setState({ showAddChangepartDialog: false, showAddEditEquipment: false, showAllEquipments: true, showConfirmationDialog: false, showDeleteConfirmationDialog: false });
        this.populateEquipmentData();
    }

    //Equipment Change Part Page Redirection
    _manageEquipmentChangePart = () => {

        window.location.replace("/inventory-changeparts?ID=" + this.state.serialNumber + "&Name=" + this.state.equipmentName);
    }

    //Submits equipment to Inventory
    _submitEquipment() {
        let equipmentMaster = {
            "EquipmentUid": this.state.serialNumber,
            "ProcessUid": this.state.equipmentType,
            "ZoneUid": this.state.Zone,
            "IsLocationFixed": this.state.isLocFixed === "Yes" ? "Y" : "N",
            "EquipmentName": this.state.equipmentName,
            "BatchScale": this.state.batchScale,
            "Id": this.state.selectedEquipmentID,
        };

        this._postEquipment(equipmentMaster);
    }

    //Populates the Equipment Data in the Page
    async populateEquipmentData() {
        const response = await fetch('Equipments', {
            method: 'get',
            headers: new Headers({
                'Authorization': 'Bearer ' + accessToken
            })
        });
        if (response.ok) {
            const data = await response.json();
            let userRoles = getUserRoles();
            let hasAdminRole = userRoles.indexOf("Admin") > -1;
            this.setState({ isAdmin: hasAdminRole, equipments: data, loading: false, showAllEquipments: true, showAddEditEquipment: false, showConfirmationDialog: false, showDeleteConfirmationDialog: false });

        }
        else {
            this.setState({ isAdmin: false, equipments: [], loading: false, showAllEquipments: true, showAddEditEquipment: false, showConfirmationDialog: false, showDeleteConfirmationDialog: false });
        }
    }

    //Gets the Equipment Type Details from Keywords
    async _getEquipmentTypeKeywords() {
        const response = await fetch('Keywords/Equipment Type', {
            method: 'get',
            headers: new Headers({
                'Authorization': 'Bearer ' + accessToken
            })
        });

        if (response.ok) {
            const equipTypesData = await response.json();
            this.setState({ equipmentTypeKeywords: equipTypesData });
        }
        else {
            this.setState({ equipmentTypeKeywords: [] });
        }
    }

    //Gets the Batch Scale Details from Keywords
    async _getBatchScaleKeywords() {
        const response = await fetch('Keywords/Batch Scale', {
            method: 'get',
            headers: new Headers({
                'Authorization': 'Bearer ' + accessToken
            })
        });
        if (response.ok) {
            const batchScaleData = await response.json();
            this.setState({ batchScaleKeywords: batchScaleData });
        }
        else {
            this.setState({ batchScaleKeywords: [] });
        }
    }

    //Creates/Updates the Equipment in Inventory
    async _postEquipment(equipmentData) {
        const requestOptions = {
            method: 'POST',
            headers: new Headers({
                'Authorization': 'Bearer ' + accessToken,
                'Content-Type': 'application/json'
            }),
            body: JSON.stringify(equipmentData)
        };
        
        await fetch("Equipments", requestOptions).then((response) => {
            if (response.ok) {
                this.setState({
                    showAddChangepartDialog: true,
                    showConfirmationDialog: false
                });
            } else {
                this.setState({
                    showAlert: true
                });
                this.populateEquipmentData();
            }
        })
    }

    //Deletes the Equipment from Inventory
    _deleteEquipment() {
        if (this.state.deletionComment) {
            fetch("Equipments/" + this.state.selectedEquipmentID + "?comment=" + this.state.deletionComment, {
                method: 'DELETE',
                headers: new Headers({
                    'Authorization': 'Bearer ' + accessToken
                })
            }).then(_ => {
                this.populateEquipmentData();
            }).catch(console.log);
        }
        else {
            this.setState({ showCommentError: true });
        }
    }

}