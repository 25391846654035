import { Button } from 'bootstrap';
import React, { Component } from 'react';
import './../custom.css';
import SpaceModal from '@gsk-space-components/space-modal'
import SpaceButton from '@gsk-space-components/button';
import SpaceTheme from '@gsk-space-components/space-theme';
import { ThemeProvider, OutlinedInput, TextField } from '@material-ui/core';
import { SpaceAutocomplete } from '@gsk-space-components/autocomplete';
import { Autocomplete } from '@material-ui/lab/Autocomplete';
import { msalAuth, getAccessToken, getUserRoles, getUserName } from '../msal/MsalAuthProvider';
import { CircularProgress } from '@gsk-platforms/gsk-circular-progress';

let accessToken = undefined;
let action="";
let storedTools = [];
let decisionToRetrieve = "";

export class ToolingBooking extends Component {
    static displayName = ToolingBooking.name;

    //Default Constructor
    constructor(props) {
        super(props);
        this.state = {
            isAdmin: true,
            toolings: [],
            allToolings: [],
            selectedToolings: [],
            toolingTypeKeywords: [],
            toolingMaster: [],
            supply:[],
            showAllToolings: true,
            showAddEditTooling: false,
            selectedToolingID: 0,
            updateTooling: false,
            updatedToolingID: "",
            deletedToolingID: "",
            actionStatus:"",
            selectedToolingType: "",
            selectedToolingName: "",
            selectedDimension: "",
            selectedSetNumber: "",
            selectedShape: "",
            selectedEmbossingUpper: "",
            selectedEmbossingLower: "",
            selectedUpperPunches: undefined,
            selectedLowerPunches: undefined,
            selectedProfileOrConcavity: "",
            selectedNumberOfDies: undefined,
            showToolingTypeError: false,
            showDimensionError: false,
            showSetNumberError: false,
            showShapeError: false,
            showEmbossingUpperError: false,
            showEmbossingLowerError: false,
            showUpperPunchesError: false,
            showLowerPunchesError: false,
            showDiesError: false,
            showProfileOrConcavityError: false,
            showConfirmationDialog: false,
            showDeleteConfirmationDialog: false,
            deletionComment: "",
            showCommentError: false,
            loading: false,
            showCurrentTooling:false
        };

        this._submitTooling = this._submitTooling.bind(this);
        this._deleteTooling = this._deleteTooling.bind(this);
        this._validateToolingInput = this._validateToolingInput.bind(this);
    }

    //Component Mount Logic
    async componentDidMount() {
        const accessTokenRequest = {
            scopes: ["api://beefd1ec-7166-4ef5-a037-80d953594bbf/access_as_user"]
        }
        var authRes;
        try {
            authRes = await msalAuth.acquireTokenSilent(accessTokenRequest);
            accessToken = authRes.accessToken
        }
        catch (error) {
            console.log("AquireTokenSilent failure");
            authRes = await msalAuth.acquireTokenPopup(accessTokenRequest);
            accessToken = authRes.accessToken
        }
        this.allToolingData();
        this._getToolingTypeKeywords();
    }

    //Renders the existing Data
    renderToolingTable(toolings) {
        return (
            
            <table className='table table-striped' aria-labelledby="tabelLabel">
                <thead>
                    <tr>
                        <th>Set Number</th>
                        <th>Tooling Type</th>
                        <th>Tooling Name</th>
                        <th>No of Upper Punches</th>
                        <th>No of Lower Punches</th>
                        <th>Number of Dies</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {(toolings === undefined || toolings === null || toolings.length === 0) && <tr><td colSpan={12}><div className={"no-record-error"}>No records found</div></td></tr>}
                    {toolings.map(tooling =>
                        <tr>
                            <td>{tooling.SetNumber}</td>
                            <td>{tooling.ToolingType}</td>
                            <td>{tooling.ToolingName}</td>
                            <td>{tooling.UpperPunches}</td>
                            <td>{tooling.LowerPunches}</td>
                            <td>{tooling.NumberOfDies}</td>
                            {<td><div style={{ display: "inline-flex" }}>
                                <button title="Edit Tooling" className={"btn-edit"} onClick={() => {
                                    this.setState({
                                        updateTooling: true,
                                        showAllToolings: false,
                                        showAddEditTooling: true,
                                        selectedToolingID: tooling.SetNumber,
                                        selectedToolingType: tooling.ToolingType,
                                        selectedDimension: tooling.Dimensions,
                                        selectedSetNumber: tooling.SetNumber.toString(),
                                        selectedShape: tooling.Shape,
                                        selectedEmbossingUpper: tooling.EmbossingUpper,
                                        selectedEmbossingLower: tooling.EmbossingLower,
                                        selectedUpperPunches: tooling.UpperPunches,
                                        selectedLowerPunches: tooling.LowerPunches,
                                        selectedNumberOfDies: tooling.NumberOfDies,
                                        selectedProfileOrConcavity: tooling.ProfileOrConcavity,
                                    });
                                }}>Edit</button>
                                <button title="Delete Tooling" className={"btn-delete"} onClick={() => {
                                    this.setState({
                                        showDeleteConfirmationDialog: true,
                                        deletedToolingID: Number(tooling.SetNumber)
                                    });
                                }}>Delete</button>
                            </div></td>}
                        </tr>
                    )}
                </tbody>
            </table>
        );
    }

    //Renders New Screen
    renderNewToolingScreen() {
        return (<div>
            <div className="formcontainer">
                <div className="formrow">
                    <div className="col-25">
                        <label >Set Number</label>
                    </div>
                    <div className="col-75">
                        <SpaceAutocomplete
                            id="basic-test"
                            options={this.state.allToolings.map(itm => itm.SetNumber.toString())}
                            getOptionLabel={(option) => option}
                            fullWidth={true}
                            placeholder="Select Set Number.."
                            value={this.state.selectedSetNumber}
                            onChange={(_event, newValue) => {
                                this.setState({
                                    selectedSetNumber: newValue, showSetNumberError: (newValue === undefined || newValue === ""),
                                    selectedToolingType: this.state.allToolings.filter(itm => itm.SetNumber.toString() === newValue).map(itm => itm.ToolingType).join(','),
                                    selectedToolingName: this.state.allToolings.filter(itm => itm.SetNumber.toString() === newValue).map(itm => itm.ToolingName).join(','),
                                    selectedDimension: this.state.allToolings.filter(itm => itm.SetNumber.toString() === newValue).map(itm => itm.Dimensions).join(','),
                                    selectedShape: this.state.allToolings.filter(itm => itm.SetNumber.toString() === newValue).map(itm => itm.Shape).join(','),
                                    selectedEmbossingUpper: this.state.allToolings.filter(itm => itm.SetNumber.toString() === newValue).map(itm => itm.EmbossingUpper).join(','),
                                    selectedEmbossingLower: this.state.allToolings.filter(itm => itm.SetNumber.toString() === newValue).map(itm => itm.EmbossingLower).join(','),
                                    selectedUpperPunches: Number(this.state.allToolings.filter(itm => itm.SetNumber.toString() === newValue).map(itm => itm.UpperPunches).join(',')),
                                    selectedLowerPunches: Number(this.state.allToolings.filter(itm => itm.SetNumber.toString() === newValue).map(itm => itm.LowerPunches).join(',')),
                                    selectedNumberOfDies: Number(this.state.allToolings.filter(itm => itm.SetNumber.toString() === newValue).map(itm => itm.NumberOfDies).join(',')),
                                    selectedProfileOrConcavity: this.state.allToolings.filter(itm => itm.SetNumber.toString() === newValue).map(itm => itm.ProfileOrConcavity).join(','),
                                })
                            }}
                            error={this.state.showSetNumberError}
                        />
                    </div>
                </div>
                <div className="formrow">
                    <div className="col-25">
                        <label >Tooling Type</label>
                    </div>
                    <div className="col-75">
                        <SpaceAutocomplete
                            id="basic-test"
                            options={["TYPE1", "TYPE2"]}
                            getOptionLabel={(option) => option}
                            fullWidth={true}
                            disabled
                            placeholder="Select Tooling Type.."
                            value={this.state.selectedToolingType}
                            onChange={(_event, newValue) => { this.setState({ selectedToolingType: newValue, showToolingTypeError: (newValue === undefined || newValue === "") }) }}
                            error={this.state.showToolingTypeError}
                        />
                    </div>
                </div>
                <div className="formrow">
                    <div className="col-25">
                        <label >Tooling Name</label>
                    </div>
                    <div className="col-75">
                        <OutlinedInput fullWidth={true} required disabled placeholder={"Enter Tooling Name.."} value={this.state.selectedToolingName} error={this.state.showToolingNameError}
                            onChange={(e) => { this.setState({ selectedToolingName: e.target.value, showToolingNameError: (e.target.value === undefined || e.target.value === "") }); }}
                        ></OutlinedInput>
                    </div>
                </div>
                <div className="formrow">
                    <div className="col-25">
                        <label >Dimensions</label>
                    </div>
                    <div className="col-75">
                        <OutlinedInput fullWidth={true} required disabled placeholder={"Enter Dimensions.."} value={this.state.selectedDimension} error={this.state.showDimensionError}
                            onChange={(e) => { this.setState({ selectedDimension: e.target.value, showDimensionError: (e.target.value === undefined || e.target.value === "") }); }}
                        ></OutlinedInput>
                    </div>
                </div>
                <div className="formrow">
                    <div className="col-25">
                        <label >Shape</label>
                    </div>
                    <div className="col-75">
                        <OutlinedInput fullWidth={true} required disabled placeholder={"Enter Shape.."} value={this.state.selectedShape} error={this.state.showShapeError}
                            onChange={(e) => { this.setState({ selectedShape: e.target.value, showShapeError: (e.target.value === undefined || e.target.value === "") }); }}
                        ></OutlinedInput>
                    </div>
                </div>
                <div className="formrow">
                    <div className="col-25">
                        <label >Embossing Upper</label>
                    </div>
                    <div className="col-75">
                        <OutlinedInput fullWidth={true} required disabled placeholder={"Enter Embossing Upper.."} value={this.state.selectedEmbossingUpper} error={this.state.showEmbossingUpperError}
                            onChange={(e) => { this.setState({ selectedEmbossingUpper: e.target.value, showEmbossingUpperError: (e.target.value === undefined || e.target.value === "") }); }}
                        ></OutlinedInput>
                    </div>
                </div>
                <div className="formrow">
                    <div className="col-25">
                        <label >Embossing Lower</label>
                    </div>
                    <div className="col-75">
                        <OutlinedInput fullWidth={true} required disabled placeholder={"Enter Embossing Lower.."} value={this.state.selectedEmbossingLower} error={this.state.showEmbossingLowerError}
                            onChange={(e) => { this.setState({ selectedEmbossingLower: e.target.value, showEmbossingLowerError: (e.target.value === undefined || e.target.value === "") }); }}
                        ></OutlinedInput>
                    </div>
                </div>
                <div className="formrow">
                    <div className="col-25">
                        <label >No Of Upper Punches</label>
                    </div>
                    <div className="col-75">
                        <OutlinedInput fullWidth={true} type="number" required disabled placeholder={"Enter No Of Upper Punches.."} value={this.state.selectedUpperPunches} error={this.state.showUpperPunchesError}
                            onChange={(e) => { this.setState({ selectedUpperPunches: (Number(e.target.value) && e.target.value > 0) ? e.target.value : this.state.selectedUpperPunches, showUpperPunchesError: (!(Number(e.target.value) && e.target.value > 0) && !this.state.selectedUpperPunches) }); }}
                        ></OutlinedInput>
                    </div>
                </div>
                <div className="formrow">
                    <div className="col-25">
                        <label >No Of Lower Punches</label>
                    </div>
                    <div className="col-75">
                        <OutlinedInput fullWidth={true} type="number" required disabled placeholder={"Enter No Of Lower Punches.."} value={this.state.selectedLowerPunches} error={this.state.showLowerPunchesError}
                            onChange={(e) => { this.setState({ selectedLowerPunches: (Number(e.target.value) && e.target.value > 0) ? e.target.value : this.state.selectedLowerPunches, showLowerPunchesError: (!(Number(e.target.value) && e.target.value > 0) && !this.state.selectedLowerPunches) }); }}
                        ></OutlinedInput>
                    </div>
                </div>
                <div className="formrow">
                    <div className="col-25">
                        <label >Number Of Dies</label>
                    </div>
                    <div className="col-75">
                        <OutlinedInput fullWidth={true} type="number" required disabled placeholder={"Enter Number Of Dies.."} value={this.state.selectedNumberOfDies} error={this.state.showDiesError}
                            onChange={(e) => { this.setState({ selectedNumberOfDies: (Number(e.target.value) && e.target.value > 0) ? e.target.value : this.state.selectedNumberOfDies, showDiesError: (!(Number(e.target.value) && e.target.value > 0) && !this.state.selectedNumberOfDies) }); }}
                        ></OutlinedInput>
                    </div>
                </div>
                <div className="formrow">
                    <div className="col-25">
                        <label >Profile/Concavity</label>
                    </div>
                    <div className="col-75">
                        <OutlinedInput fullWidth={true} required disabled placeholder={"Enter Profile/Concavity.."} value={this.state.selectedProfileOrConcavity} error={this.state.showProfileOrConcavityError}
                            onChange={(e) => { this.setState({ selectedProfileOrConcavity: e.target.value, showProfileOrConcavityError: (e.target.value === undefined || e.target.value === "") }); }}
                        ></OutlinedInput>
                    </div>
                </div>
                <div className="formrow">
                    <button className={"submitBtn"} onClick={this._validateToolingInput}>Add</button>
                    <button className={"cancelBtn"} onClick={() => {
                        this.setState({
                            updateTooling: false,
                            showAddEditTooling: false,
                            showAllToolings: true,
                            showToolingTypeError: false,
                            showDimensionError: false,
                            showSetNumberError: false,
                            showShapeError: false,
                            showEmbossingUpperError: false,
                            showEmbossingLowerError: false,
                            showUpperPunchesError: false,
                            showLowerPunchesError: false,
                            showDiesError: false,
                            showProfileOrConcavityError: false,
                        });
                    }}>Cancel</button>
                </div>
            </div>
        </div >);
    }

    //Renders the page based on New/Edit screen
    render() {
        return (
            <div>
                {this.state.loading && <p><gsk-circular-progress> </gsk-circular-progress></p>}
                {!this.state.loading && this.state.showAllToolings &&
                    <div>
                        <div style={{ textAlign: "right" }}>
                            {<button title="Create New Tooling" className={"btn-add"} onClick={() => {
                                this.setState({
                                    showAllToolings: false,
                                    showAddEditTooling: true,
                                    selectedToolingType: "",
                                    selectedToolingName: "",
                                    selectedDimension: "",
                                    selectedSetNumber: "",
                                    selectedShape: "",
                                    selectedEmbossingUpper: "",
                                    selectedEmbossingLower: "",
                                    selectedUpperPunches: undefined,
                                    selectedLowerPunches: undefined,
                                    selectedNumberOfDies: undefined,
                                    selectedProfileOrConcavity: ""
                                });
                            }}>Add Tooling</button>}
                        </div>
                        <div className={"rootContent"}>{this.renderToolingTable(this.state.toolings)}</div>
                    </div>}
                {!this.state.loading && this.state.showAddEditTooling && <div>{this.renderNewToolingScreen()}</div>}
                {this.state.showConfirmationDialog && <SpaceModal
                    open={this.state.showConfirmationDialog}
                    titleText="Save Tool"
                    body="Would you like to save this Tool?"
                    mainButtonLabel="Yes"
                    secondaryButtonLabel="No"
                    mainButtonFunction={this._submitTooling}
                    secondaryButtonFunction={() => { this.setState({ showAddEditTooling: false, showAllToolings: true, showConfirmationDialog: false, showDeleteConfirmationDialog: false }) }}
                    id="saveToolDialog"
                />}
                {this.state.showDeleteConfirmationDialog && <SpaceModal
                    open={this.state.showDeleteConfirmationDialog}
                    titleText="Delete Tool"
                    body={<div>Would you like to delete this Tool?</div>}
                    mainButtonLabel="Yes"
                    secondaryButtonLabel="No"
                    mainButtonFunction={this._deleteTooling}
                    secondaryButtonFunction={() => { this.setState({ showAddEditTooling: false, showAllToolings: true, showConfirmationDialog: false, showDeleteConfirmationDialog: false }) }}
                    id="deleteToolDialog"
                />}
            </div>
        );
    }

    //Gets all the Tooling Data
    async allToolingData() {
        const response = await fetch('Tooling', {
            method: 'get',
            headers: new Headers({
                'Authorization': 'Bearer ' + accessToken
            })
        });
        if (response.ok) {
            const data = await response.json();
            let userRoles = getUserRoles();
            let hasAdminRole = userRoles.indexOf("Admin") > -1;
            this.setState({ isAdmin: hasAdminRole, allToolings: data });

            action = localStorage.getItem('Action')
            decisionToRetrieve = localStorage.getItem('UserFixedDecision');
            storedTools = JSON.parse(localStorage.getItem("ToolingsBooking") || '{}');

            if (storedTools !== null && storedTools !== "" && Object.entries(storedTools).length !== 0) {
                this.setState({ toolings: storedTools, toolingMaster: storedTools })
            } else if (action === "Add") {
                this.setState({ toolings: [] })
            }
            this.state.loading = false;
        }
        else {
            this.setState({ isAdmin: false, allToolings: [] });
        }
    }

    //Gets all the Tooling type Keywords
    async _getToolingTypeKeywords() {
        const response = await fetch('Keywords/Tooling Type', {
            method: 'get',
            headers: new Headers({
                'Authorization': 'Bearer ' + accessToken
            })
        });
        if (response.ok) {
            const toolingTypesData = await response.json();
            this.setState({ toolingTypeKeywords: toolingTypesData });
        }
        else {
            this.setState({ toolingTypeKeywords: [] });
        }
    }

    //Validates the Input provided by User
    _validateToolingInput() {
        let canSave = true;
        if (!this.state.selectedSetNumber) {
            this.setState({ showSetNumberError: true });
            canSave = false;
        }
        
        if (canSave) {
            this.setState({
                showToolingTypeError: false,
                showDimensionError: false,
                showSetNumberError: false,
                showShapeError: false,
                showEmbossingUpperError: false,
                showEmbossingLowerError: false,
                showLowerPunchesError: false,
                showUpperPunchesError: false,
                showProfileOrConcavityError: false,
                showConfirmationDialog: true

            });
        }
    }

    //Submits the Tooling Data
    _submitTooling() {

        if (this.state.updateTooling) {

            let updatedDetail = {
                "SetNumber": Number(this.state.selectedSetNumber),
                "ToolingType": this.state.selectedToolingType,
                "ToolingName": this.state.selectedToolingName,
                "Dimensions": this.state.selectedDimension,
                "Shape": this.state.selectedShape,
                "EmbossingUpper": this.state.selectedEmbossingUpper,
                "EmbossingLower": this.state.selectedEmbossingLower,
                "LowerPunches": Number(this.state.selectedLowerPunches),
                "UpperPunches": Number(this.state.selectedUpperPunches),
                "ProfileOrConcavity": this.state.selectedProfileOrConcavity,
                "NumberOfDies": Number(this.state.selectedNumberOfDies)
            }
            for (let i = 0; i < this.state.toolingMaster.length; i++) {
                if (this.state.toolingMaster[i].SetNumber === this.state.selectedToolingID) {
                    this.state.toolingMaster.splice(i, 1, updatedDetail);
                }
            }
        }
        else {
            this.state.toolingMaster.push({
                "SetNumber": Number(this.state.selectedSetNumber),
                "ToolingType": this.state.selectedToolingType,
                "ToolingName": this.state.selectedToolingName,
                "Dimensions": this.state.selectedDimension,
                "Shape": this.state.selectedShape,
                "EmbossingUpper": this.state.selectedEmbossingUpper,
                "EmbossingLower": this.state.selectedEmbossingLower,
                "LowerPunches": Number(this.state.selectedLowerPunches),
                "UpperPunches": Number(this.state.selectedUpperPunches),
                "ProfileOrConcavity": this.state.selectedProfileOrConcavity,
                "NumberOfDies": Number(this.state.selectedNumberOfDies)
            });
        }

        this.state.updateTooling = false;
        this._setToolings(this.state.toolingMaster);
        localStorage.removeItem('ToolingsBooking');
        localStorage.setItem("ToolingsBooking", JSON.stringify(this.state.toolingMaster));
    }

    _setToolings(toolingMaster) {
        let userRoles = getUserRoles();
        let hasAdminRole = userRoles.indexOf("Admin") > -1;
        this.setState({ isAdmin: hasAdminRole, toolings: toolingMaster, loading: false, showAllToolings: true, showAddEditTooling: false, showConfirmationDialog: false, showDeleteConfirmationDialog: false });
    }


    //Deletes the tooling Details
    _deleteTooling() {
        
        for (let i = 0; i < this.state.toolingMaster.length; i++) {
            if (this.state.toolingMaster[i].SetNumber === this.state.deletedToolingID) {
                this.state.toolingMaster.splice(i, 1);
            }
        }

        this._setToolings(this.state.toolingMaster);
        localStorage.removeItem('ToolingsBooking');
        localStorage.setItem("ToolingsBooking", JSON.stringify(this.state.toolingMaster));
    }
}